import { useState, useEffect, useRef } from "react";

export const useShortcutNavigation = (shortcuts, onSelect) => {
  const [activeIndex, setActiveIndex] = useState(-1); // Índice del atajo activo
  const [isNavigationActive, setIsNavigationActive] = useState(false); // Controla si está activa la navegación
  const activeShortcutRef = useRef(null); // Referencia para el atajo activo

  const handleKeyNavigation = (e) => {
    if (!isNavigationActive || shortcuts.length === 0) return;

    if (e.key === "ArrowDown") {
      setActiveIndex((prevIndex) =>
        prevIndex < shortcuts.length - 1 ? prevIndex + 1 : 0
      );
    } else if (e.key === "ArrowUp") {
      setActiveIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : shortcuts.length - 1
      );
    } else if (e.key === "Enter" && activeIndex !== -1) {
      onSelect(shortcuts[activeIndex]); // Llama a la función de selección
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => handleKeyNavigation(e);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [shortcuts, activeIndex, isNavigationActive]);

  useEffect(() => {
    // Desplaza el contenedor si hay un atajo activo
    if (activeShortcutRef.current) {
      activeShortcutRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [activeIndex]);

  return {
    activeIndex,
    setActiveIndex,
    setIsNavigationActive,
    activeShortcutRef,
  };
};
