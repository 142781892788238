/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
'use client'
import {useRef, useState } from "react";
import { generateRandomId } from "../../utils/generateRandomId";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText";
import CustomTextArea from "../FormComponents/CustomTextArea/CustomTextArea";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import styles from "./DetailStoreCauseForm.module.css"
import MainButton from "../MainButton/MainButton";
import DeleteButton from "../DeleteButton/DeleteButton";
import CustomInputSwitch from "../FormComponents/CustomInputSwitch/CustomInputSwitch";
import AttachImg from "../assets/Icons/AttachImg";
import toastFunction from "../../utils/toastFunction";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import CustomInputNumber from "../FormComponents/CustomInputNumber/CustomInputNumber";
import { ColorPicker } from 'primereact/colorpicker';
import { Toast } from "primereact/toast";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog";
import { processBase64Media } from "../../utils/processMediaArray";
import { isBase64 } from "../../utils/utilsFunctions";



const DetailStoreCauseForm = ({ onCloseStoreForm, cause, hasEditCause,  t, shoppexStoreValues, setShoppexStoreValues }) => {
    const toast = useRef(null);
    const {globalLoading, setGlobalLoading} = useLoaderContext();
    const {deleteFolderStorageAndContents, updateArray, addToArray, removeFromArray} = useFirebaseCRUD();
    const [visibleConfirmDeleteCauseModal, setVisibleConfirmDeleteCauseModal] = useState(false)
    const fileInputRef = useRef(null);
    const MAX_IMAGE_SIZE = 1 * 1024 * 1024; // 1MB
  
    const causeInitialValues = {
        id: cause?.id || generateRandomId(),
        amount: cause?.amount || 0,
        border_color: cause?.border_color || null,
        description: cause?.description || null,
        icon_svg_url: cause?.icon_svg_url || null,
        isActive: cause?.isActive || true,
        tab_text: cause?.tab_text || null,
        text_border_color: cause?.text_border_color || null,
        title: cause?.title || null,
        video_id: cause?.video_id || null,
        video_url: cause?.video_url || null,
    }
    const [causeValues, setCauseValues] = useState(causeInitialValues);
    const [color, setColor] = useState(causeInitialValues?.border_color);
    
  const handleInputChange = (fieldName, value) => {
      if(fieldName === "video_url") {
          setCauseValues((prev) => ({
              ...prev,
              [fieldName]: value,
              ...(fieldName === "video_url" && { video_id: extractVideoId(value) }),
            }));
      }else if(fieldName === "border_color") {
        setCauseValues((prevData) => ({
          ...prevData,
          [fieldName]: value,
        }));
        setColor(value);
      }else {
          setCauseValues((prevState) => {
            return {...prevState, [fieldName]: value}
          })
      }
  };

  const extractVideoId = (url) => {
      try {
        const urlObj = new URL(url);
        if (urlObj.hostname === "www.youtube.com" || urlObj.hostname === "youtube.com") {
          return urlObj.searchParams.get("v") || "";
        }
        if (urlObj.hostname === "youtu.be") {
          return urlObj.pathname.substring(1);
        }
      } catch (error) {
        console.error("Invalid URL");
      }
      return "";
  };

  // Procesamos si hay nuevo contenido multimedia en la causa actual de la tienda 
  const processCause = async (cause, shoppexStoreValuesId,) => {
    try {
      if (isBase64(cause?.icon_svg_url)) {
        const urlStoreFavicon = await processBase64Media(
          `shoppexStores/${shoppexStoreValuesId}/${cause.id}/icon_svg_url`,
          cause.icon_svg_url,
          "img"
        );
        return {
          ...cause,
          icon_svg_url: urlStoreFavicon,
          icon_svg_path: `shoppexStores/${shoppexStoreValuesId}/${cause.id}/icon_svg_url`,
        };
      } else {
        return {
          ...cause,
          icon_svg_url: cause?.icon_svg_url,
        };
      }
    } catch (error) {
      console.error(`Error processing media for cause ID ${cause.id}:`, error);
      return cause; // Devuelve el objeto original si hay un error
    }
  };

  const saveStoreCause = async (e) => {
    e.preventDefault()
    if ( causeValues?.amount === 0 || causeValues?.amount < 0) {toastFunction(toast, "warn", "El monto de la causa debe ser mayor a 0"); return}
    setGlobalLoading(true);
    
    let newCauseValues = {...causeValues}
      let result;
      e.preventDefault(); 
      try {
        newCauseValues = await processCause(newCauseValues, shoppexStoreValues.id)
      } catch (error) {
        console.error("Error al procesar la causas:", error);
      }
      if (hasEditCause) {
          result = await updateArray("stores", shoppexStoreValues?.id, "causes", cause, newCauseValues );
      }else {
        result = await addToArray("stores",shoppexStoreValues?.id, "causes", newCauseValues );
      }
      console.log(result, toast)

      if (result) {
        toastFunction(toast, "success", "Causa guardado con exito", "", 3000);
    } else {
        toastFunction(toast, "error", "Hubo un error al guardar el Causa", "", 4000);
    }
      setTimeout(() => {
        setGlobalLoading(false);
        onCloseStoreForm()
      }, 100); 
     
  };

  const deleteStoreCause = async () => {
    console.log("borrando causa")
    setGlobalLoading(true);
    try {
        const storeCauses = [...shoppexStoreValues?.causes];
        const causesWhithoutSelectedCause = storeCauses.filter((cause) => cause.id !== causeValues.id);
        console.log("causesWhithoutSelectedCause", causesWhithoutSelectedCause);
        const pathToDelete =`shoppexStores/${shoppexStoreValues.id}/${cause.id}/`;
        await deleteFolderStorageAndContents(pathToDelete);

        setShoppexStoreValues((prevStae) => {
            return {...prevStae, "causes": [...causesWhithoutSelectedCause]}
        })

        const result = await removeFromArray("stores", shoppexStoreValues.id, "causes", cause);
        
        if (result) {
            toastFunction(toast, "success", "Causa eliminado con exito", "", 3000);
        } else {
            toastFunction(toast, "error", "Hubo un error al eliminar el causa", "", 3000);
        }


        setTimeout(() => {
          setGlobalLoading(false);
          onCloseStoreForm()
        }, 100);   
    } catch (error) {
        console.error(error)
    }
  };


  const handleVisibleFileReaderInputImg = () => {
    fileInputRef.current.click();
  };
 
  const handleMainCauseIcon = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file.size > MAX_IMAGE_SIZE) {
        toastFunction(toast, "warn", "La imagen supera el tamaño permitido - 1MB", "", 3000);
        return;
    }

    reader.onloadend = async () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = async () => {
          const canvas = document.createElement("canvas");
          const scaleFactor = 0.8;
          canvas.width = img.width * scaleFactor;
          canvas.height = img.height * scaleFactor;
      
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      
          // Convertir el canvas a base64, manteniendo todo el base64
          const base64Image = canvas.toDataURL("image/jpeg", 0.8); // No usamos .split(",")[1] para evitar el recorte
          
          // Actualizar el estado después de procesar la imagen
          setCauseValues({
              ...causeValues,
              icon_svg_url: base64Image, // Guarda el base64 completo
          });
      };
    };

    if (file) {
        reader.readAsDataURL(file);
    }
  };
    
    return (
        <form 
        onSubmit={saveStoreCause}

            className="grid grid-cols-1 ">
              <Toast ref={toast} />
                
                   {/* <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" /> */}
                <label className="justify-center flex mt-2  text-sm font-medium mb-2">Icono de la causa </label>

                <div className="flex justify-center">
                        {imgWrapperUser(handleVisibleFileReaderInputImg, causeValues, handleMainCauseIcon, fileInputRef, globalLoading)}
                </div>
                <label className="justify-center flex mt-4  text-sm font-medium mb-2 ">Colores de la causa </label>
                <span className="w-full items-center flex justify-center mb-8">
                  <ColorPicker value={color} onChange={(e) => handleInputChange("border_color", e.value)} inline />
              </span>
             <CustomInputText
                    floatLabel={true}
                    type={"text"}
                    value={causeValues.title}
                    placeholder={t("input-placeholders.title")}
                    onChange={(e) => handleInputChange("title", e.target.value)}
                    disabled={globalLoading}
                    required={true}
                />
                
                  <CustomInputText
                    floatLabel={true}
                    type={"text"}
                    value={causeValues.tab_text}
                    placeholder={t("input-placeholders.tabText")}
                    onChange={(e) => handleInputChange("tab_text", e.target.value)}
                    disabled={globalLoading}
                    required={true}
                />
             <CustomTextArea
                    value={causeValues.description}
                    placeholder={t("input-placeholders.description")}
                    onChange={(e) => handleInputChange("description", e.target.value)}
                    floatLabel={true}
                    disabled={globalLoading}
                    required={true}
                />
                <CustomInputNumber
                    floatLabel={true}
                    className="p-2 m-0 w-full"
                    inputId="currency-cop"
                    mode="currency"
                    currency="COP"
                    locale="es-CO"
                    name="amount"
                    value={causeValues?.amount}
                    onChange={(e) => handleInputChange("amount", e.value)}
                    placeholder={t("input-placeholders.amount")}

                    disabled={globalLoading}
                    required={true}
                />
                <CustomInputText
                    floatLabel={true}
                    type={"text"}
                    value={causeValues.video_url}
                    placeholder={t("input-placeholders.videoUrl")}
                    onChange={(e) => handleInputChange("video_url", e.target.value)}
                    disabled={globalLoading}
                    required={true}
                />
                 <CustomInputText
                    floatLabel={true}
                    type={"text"}
                    value={causeValues.video_id}
                    placeholder={t("input-placeholders.videoId")}
                    onChange={(e) => handleInputChange("video_id", e.target.value)}
                    disabled={true}
                    required={true}
                />

                <div className="mt-8">
                    <YouTubePlayer videoId={causeValues.video_id}/>
                </div>
                <div className={`${styles.containerSwitch} mt-12`}>
                    <CustomInputSwitch
                        checked={causeValues.isActive}
                        onChange={(e) => handleInputChange("isActive", e.target.value)}
                        disabled={globalLoading}
                    />
                    <h5>{t("input-placeholders.isActive")}</h5>
                </div>
                <div className="mt-12 grid gap-4">
                <MainButton
                    type="submit"
                    label={t("saveCause")}
                    loading={globalLoading}
                />
               { hasEditCause && ( <DeleteButton
                    type={"button"}
                    label={t("deleteCause")}
                    loading={globalLoading}
                    onClick={() => setVisibleConfirmDeleteCauseModal(true)}
                    disabled={globalLoading}
                />)}
            </div>
            <CustomConfirmDialog
              visible={visibleConfirmDeleteCauseModal}
              onHide={() => setVisibleConfirmDeleteCauseModal(false)}
              header={t("confirmDialog-deleteCause.header")} 
              message={t("confirmDialog-deleteCause.message")}
              icon="pi pi-question-circle"
              accept={deleteStoreCause}
              reject={() => setVisibleConfirmDeleteCauseModal(false)}
              acceptLabel={t("confirmDialog-deleteCause.acceptLabel")}
              rejectLabel={t("confirmDialog-deleteCause.rejectLabel")}
            />
        </form>
    )
}

export default DetailStoreCauseForm;


const YouTubePlayer = ({ videoId, width = "100%", height = "315" }) => {
    if (!videoId) {
      return <p>Por favor, proporciona un ID de video válido.</p>;
    }
  
    const videoUrl = `https://www.youtube.com/embed/${videoId}`;
  
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <iframe
          width={width}
          height={height}
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
};

const imgWrapperUser = (handleVisibleFileReaderInputImg, causeValues, handleMainCauseIcon, fileInputRef, globalLoading) => {
    return (
      <div className="">
        <div
          className="bg-slate-100 rounded-full h-28 w-28 mb-6 mt-8 relative overflow-hidden"
          onClick={handleVisibleFileReaderInputImg}
          style={{
            cursor: "pointer",
          }}
        >
          {!causeValues?.icon_svg_url && (
                <button className="opacity-40 pointer mt-6 ml-1" type="button"
                disabled={globalLoading}
                >
                    {" "}
                    <AttachImg />
                </button>
                )}
          {causeValues?.icon_svg_url && (
            <div className="absolute inset-0">
            <img
              src={causeValues?.icon_svg_url}
              className="w-full h-full object-cover rounded-full"
              style={{ pointerEvents: "none" }}
              disabled={globalLoading}
            />
            </div>
          )}
  
          <div className="my-4 bg-red-500">
            <input type="file" accept="image/*" onChange={handleMainCauseIcon} style={{ display: "none" }} ref={fileInputRef} />
          </div>
        </div>
      </div>
    );
  }
  