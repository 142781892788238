import  { createContext, useState, useContext, useRef } from 'react';
import { CHATMESSAGE, SIDEBARMENU } from '../utils/CRMconstants';
import { fetchFromCloudFunction } from '../../../services/cloudFunctinons/fetchFromCloudFunction';
import { getDownloadURL, getStorage, ref, uploadBytes, uploadString } from 'firebase/storage';
import { storage } from '../../../firebase/firebase';
import toastFunction from '../../../utils/toastFunction';
import useShopifyRequest from '../../../hooks/useShopifyRequest';

// Crea el contexto
const ChatMenuContext = createContext();

// Proveedor del contexto
// eslint-disable-next-line react/prop-types
export const ChatMenuProvider = ({ children }) => {
  
  const [selectedMenuOption, setSelectedMenuOption] = useState(null);
  const [messageData, setMessageData] = useState(null); // Estado auxiliar para almacenar el mensaje previo


  const [imagePreviewUrls, setImagePreviewUrls] = useState([]);
  const [documentFiles, setDocumentFiles] = useState([]); 
  const [errorMessage, setErrorMessage] = useState('');

  const { toast } = useShopifyRequest();


  const handleChatMessageMenu = ({ type, messageId, messageText, videoSrc, audioSrc, username, imageSrc, idMessageRepliying, audioDuration}) => {
    console.log("type", type)
    // Siempre actualiza messageData al seleccionar un mensaje
    setMessageData({ messageId, messageText, videoSrc, audioSrc, username, imageSrc, type, idMessageRepliying, audioDuration});
    
    // Si se selecciona un nuevo mensaje, también podemos configurar la opción inicial a null (o cualquier otra lógica que necesites)
    setSelectedMenuOption((prevState) => ({
      ...prevState,
      option: null, // O mantener la opción anterior si deseas
    }));
  };

  const handleResponseMessage = ({ messageId, messageText, videoSrc, audioSrc, username, imageSrc, type, idMessageRepliying, audioDuration }) => {
    const isSameMessage =
      selectedMenuOption?.messageId === messageId &&
      selectedMenuOption?.messageText === messageText &&
      selectedMenuOption?.videoSrc === videoSrc &&
      selectedMenuOption?.audioSrc === audioSrc &&
      selectedMenuOption?.imageSrc === imageSrc &&
      selectedMenuOption?.type === type &&
      selectedMenuOption?.idMessageRepliying === idMessageRepliying &&
      selectedMenuOption?.audioDuration ===  audioDuration &&
      selectedMenuOption?.username === username;


    if (!isSameMessage) {
      // Si el mensaje es diferente, actualiza selectedMenuOption y messageData
      setSelectedMenuOption({
        messageId,
        messageText,
        videoSrc,
        audioSrc,
        username,
        imageSrc,
        type,
        idMessageRepliying: messageId,
        audioDuration,
        option: 1, // Cambia la opción a 1 para indicar "respuesta"
      });
      setMessageData({ messageId, messageText, videoSrc, audioSrc, imageSrc, username, type, idMessageRepliying: messageId, audioDuration});
    } else if (selectedMenuOption?.option !== 1) {
      // Si el mensaje es el mismo, mantiene la opción actual en 1
      setSelectedMenuOption((prevState) => ({
        ...prevState,
        option: 1,
      }));
    }
  };

  const handleFoo = () => {
    setSelectedMenuOption((prevState) => ({
      ...prevState,
      option: 2, // Cambia la opción a 2 para la acción "foo"
    }));
  };

  const menuChatOptions = () => [
    {
      label: "Responder",
      icon: "pi pi-fw pi-reply",
      command: () => handleResponseMessage(messageData),
    },
    {
      label: "foo",
      icon: "pi pi-fw pi-file",
      command: handleFoo,
    },
  ];

  const shortcuts = [
    { title: "saludo", content: "Hola, ¿en qué puedo ayudarte hoy?" },
    { title: "despedida", content: "Gracias por comunicarte, ¡hasta luego!" },
    { title: "preguntaProducto", content: "¿Podrías darme más detalles sobre el producto?" },
    { title: "confirmacionPedido", content: "Tu pedido ha sido confirmado y está en proceso." },
    { title: "seguimientoEnvio", content: "Puedes hacer seguimiento de tu envío con el siguiente enlace." },
    { title: "consultaHorario", content: "Nuestro horario de atención es de 9:00 a 18:00 de lunes a viernes." },
    { title: "informacionPago", content: "Aceptamos pagos mediante tarjeta de crédito, débito y transferencias bancarias." },
    { title: "tiempoEntrega", content: "El tiempo estimado de entrega es de 3 a 5 días hábiles." },
    { title: "devolucionProducto", content: "Puedes realizar la devolución del producto dentro de los 30 días posteriores a la compra." },
    { title: "promocionActual", content: "Actualmente tenemos un 20% de descuento en productos seleccionados." },
    { title: "soporteTecnico", content: "Nuestro equipo de soporte técnico está disponible para ayudarte con cualquier problema." },
    { title: "instruccionesRegistro", content: "Para registrarte, haz clic en el botón de 'Crear cuenta' y completa el formulario." },
    { title: "politicasPrivacidad", content: "Puedes consultar nuestras políticas de privacidad en nuestro sitio web." },
    { title: "ayudaAdicional", content: "Si necesitas ayuda adicional, no dudes en contactarnos por este medio." },
    { title: "felicitacionCumpleaños", content: "¡Feliz cumpleaños! Esperamos que tengas un gran día." },
    { title: "confirmacionCita", content: "Tu cita ha sido confirmada. Te esperamos en la fecha y hora acordadas." },
    { title: "recordatorioRenovacion", content: "Este es un recordatorio de que tu renovación se acerca. ¿Te gustaría renovarlo ahora?" },
    { title: "instruccionesDescarga", content: "Haz clic en el siguiente enlace para descargar el archivo que necesitas." },
    { title: "agradecimientoCompra", content: "Gracias por tu compra. Esperamos que disfrutes de tu producto." },
  ];

  const chatMessagesExample = [
    {
      id: 1,
      type: "text",
      avatarSrc: "https://randomuser.me/api/portraits/lego/1.jpg",
      username: "Cliente 1",
      time: new Date(2024, 10, 22, 9, 18), // 22 de noviembre de 2024, 09:18 AM
      messageText: "Hola buenas para unos zapaticos bien bacanos",
      isSent: false,
    },
    {
      id: 2,
      type: "text",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: new Date(2024, 10, 22, 9, 19), // 1 minuto después
      messageText: "Se le tienen compadre",
      isSent: true,
    },
    {
      id: 3,
      type: "text",
      avatarSrc: "https://randomuser.me/api/portraits/lego/1.jpg",
      username: "Cliente 1",
      time: new Date(2024, 10, 22, 9, 20), // 1 minuto después
      messageText: (
        <>
          <img src="image.png" alt="Linner" />
          Vi estos en la pagina, muy bacana x cierto, salu2 q valen?
        </>
      ),
      isSent: false,
    },
    {
      id: 4,
      type: "text",
      avatarSrc: "https://randomuser.me/api/portraits/lego/1.jpg",
      username: "Cliente 1",
      time: new Date(2024, 10, 22, 9, 25), // 5 minutos después
      messageText: (
        <div>
          Tienes esta otra referencia? <br />
          <a href="https://www.instagram.com/p/Cq2AzG" target="_blank" rel="noopener noreferrer">
            https://www.instagram.com/p/Cq2AzGsadaayudasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdaayudaasdasdsadasdasdasd
          </a>
        </div>
      ),
      isSent: false,
    },
    {
      id: 5,
      type: "text",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: new Date(2024, 10, 22, 9, 30), // 5 minutos después
      messageText: "20 millones brother",
      isSent: true,
    },
    {
      id: 6,
      type: "audio",
      avatarSrc: "https://randomuser.me/api/portraits/lego/1.jpg",
      username: "Cliente 1",
      time: new Date(2024, 10, 22, 9, 35), // 5 minutos después
      audioSrc:
        "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/Lil%20Tecca%20-%20DUI%20(Official%20Audio)%20-%20Lil%20Tecca.mp3?alt=media&token=0dde6991-f89a-44b5-bae3-0767ff2a31bb",
      audioDuration: "2:30",
      isSent: false,
    },
    {
      id: 7,
      type: "video",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: new Date(2024, 10, 22, 9, 40), // 5 minutos después
      messageText: "y que te parece esta otra referencia",
      videoSrc:
        "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/shoppexStores%2FJDfK9GpHVJldVzqFKRGt%2Fsection%2F19DgQGqvLnOKsOqgMU4r%2Farchive?alt=media&token=a7214314-5e4a-4bfc-b854-9037ec14e355",
      isSent: true,
    },
    {
      id: 8,
      type: "image",
      avatarSrc: "https://randomuser.me/api/portraits/lego/1.jpg",
      username: "Cliente 1",
      time: new Date(2024, 10, 22, 11, 35), // 2 horas después
      messageText: "Mira es esta ejemplo ",
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/products%2F0ObagfvFDbPh1xF7p06g%2Fmain_img%2FHnX4Z55QXegAZhXRp1CM?alt=media&token=e3cad75b-fe77-4422-b365-f68314b10fae",
      isSent: false,
    },
    {
      id: 9,
      type: "image",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: new Date(2024, 10, 22, 11, 40), // 5 minutos después
      messageText: "nuevo pedido",
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/products%2F0ObagfvFDbPh1xF7p06g%2Fmain_img%2FHnX4Z55QXegAZhXRp1CM?alt=media&token=e3cad75b-fe77-4422-b365-f68314b10fae",
      isSent: true,
      isRepliying: true,
      typeRepliying: "video",
      videoSrcRepliying:
        "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/shoppexStores%2FJDfK9GpHVJldVzqFKRGt%2Fsection%2F19DgQGqvLnOKsOqgMU4r%2Farchive?alt=media&token=a7214314-5e4a-4bfc-b854-9037ec14e355",
      idMessageRepliying: 7,
    },
    {
      id: 10,
      type: "text",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: new Date(2024, 10, 22, 11, 45), // 5 minutos después
      messageText: "20 millones brother",
      isSent: true,
      isRepliying: true,
      messageTextRepliying: "Mira es esta ejemplo ",
      typeRepliying: "image",
      imgSrcRepliying:
        "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/products%2F0ObagfvFDbPh1xF7p06g%2Fmain_img%2FHnX4Z55QXegAZhXRp1CM?alt=media&token=e3cad75b-fe77-4422-b365-f68314b10fae",
      idMessageRepliying: 8,
    },
    {
      id: 11,
      type: "text",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: new Date(2024, 10, 24, 12, 45), // 5 minutos después
      messageText: "20 millones brother",
      isSent: true,
      isRepliying: true,
      typeRepliying:"text",
      messageTextRepliying: "Respuesta",
      idMessageRepliying: 10,
    }
  ];

  const scrollToElementById = (idMessageRepliying) => {
    console.log(idMessageRepliying);
    const element = document.getElementById(idMessageRepliying);
    if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
        console.warn(`Element with ID "${idMessageRepliying}" not found.`);
    }
  }

  
  const removeImage = (index) => {
    setImagePreviewUrls((prev) => prev.filter((_, i) => i !== index));
  };

  const removeDocument = (index) => {
    setDocumentFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const [voice, setVoice] = useState(false);
  const [visibleMicrophoneHandler, setVisibleMicrophoneHandler] = useState();

  const [ responseMode, setResponseMode ] = useState(chatMessagesExample && selectedMenuOption?.option=== 1 ? true : false);
  
  const [audioUrl, setAudioUrl] = useState(null); // Estado para almacenar la URL del audio grabado
  const [recordBlobLink, setRecordBlobLink] = useState(null);

  const [elapsedTime, setElapsedTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [loadingMediaDevices, setLoadingMediaDevices] = useState(false);

  const [textValue, setTextValue] = useState('');

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

    // Función para limpiar el estado
  const clearHandle = () => {
    console.log("clearHandle", recordBlobLink)
      if (recordBlobLink || audioUrl) {
          // URL.revokeObjectURL(recordBlobLink); // Liberar el Object URL
      }
      setVoice(false);
      setVisibleMicrophoneHandler(false)
      setRecordBlobLink(null);
      setAudioUrl(null)
  };

 

  const selectDocuments = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf,.doc,.docx,.txt,.xls,.xlsx,.ppt,.pptx';
    input.multiple = true; // Permite seleccionar múltiples archivos
    input.onchange = (event) => {
      const files = event.target.files; // Obtiene todos los archivos seleccionados
      const newDocuments = [];
      let error = false;
  
      for (const file of files) {
        const fileType = file.type;
  
        // Verificar si es un archivo compatible
        if (
          fileType === 'application/pdf' ||
          fileType.startsWith('application/vnd.openxmlformats-officedocument') ||
          fileType.startsWith('application/msword') || // Compatibilidad con .doc
          fileType.startsWith('text/plain') || // Compatibilidad con .txt
          fileType.startsWith('application/vnd.ms-excel') || // Compatibilidad con .xls
          fileType.startsWith('application/vnd.ms-powerpoint') // Compatibilidad con .ppt
        ) {
          const newDocument = { name: file.name, url: URL.createObjectURL(file) };
          newDocuments.push(newDocument);
        } else {
          error = true;
          setErrorMessage('Tipo de archivo no admitido. Solo se permiten documentos PDF, DOC, DOCX, TXT, XLS, XLSX, PPT, y PPTX.');
        }
      }
  
      // Si no hubo errores, actualizamos el estado
      if (!error) {
        setDocumentFiles((prev) => [...prev, ...newDocuments]);
        setErrorMessage('');
      }
    };
  
    input.click();
  };

  // Función para seleccionar fotos o videos y actualizar el estado
  const selectMedia = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*,video/*';
    input.multiple = true; // Permite seleccionar múltiples archivos
    input.onchange = (event) => {
      const files = event.target.files; // Obtiene todos los archivos seleccionados
      const newImagePreviews = [];
      let error = false;

      for (const file of files) {
        const fileType = file.type;
        if (fileType.startsWith('image/')) {
          const reader = new FileReader();
          reader.onloadend = () => {
            newImagePreviews.push(reader.result);
            // Actualizar estado solo después de procesar todos los archivos
            if (newImagePreviews.length === files.length && !error) {
              setImagePreviewUrls((prev) => [...prev, ...newImagePreviews]);
              setErrorMessage('');
            }
          };
          reader.readAsDataURL(file);
        } else if (!fileType.startsWith('video/')) {
          error = true;
          setErrorMessage('Tipo de archivo no admitido. Solo se permiten imágenes y videos.');
        }
      }
    };
    input.click();
  };

  const startHandle = async() => {
    setElapsedTime(0)
    setVoice(true);
    setLoadingMediaDevices(true)
    try {

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      console.log(stream);
      mediaRecorderRef.current = new MediaRecorder(stream);
      console.log("mediaRecorderRef", mediaRecorderRef)

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
        const audioUrl = URL.createObjectURL(audioBlob);
        console.log("audioURL", audioUrl)
        setAudioUrl(audioUrl);
        audioChunksRef.current = []; // Limpiar el buffer de audio
      };

      mediaRecorderRef.current.start();
      setVisibleMicrophoneHandler(true);
      setIsRunning(true)
      setLoadingMediaDevices(false)

    } catch (error) {
      console.error("Error al acceder al micrófono:", error);
    }
  }

  const stopHandle = () => {
      setVoice(false);
      console.log("validacion", mediaRecorderRef.current, )
      if (mediaRecorderRef.current) {
              mediaRecorderRef.current.stop();
              // setIsRunning(false)
            }
  }

      // Función que se ejecuta cuando se detiene la grabación antigua
    const onStop = (recordedBlob) => {
        // const audioUrl = URL.createObjectURL(recordedBlob.blob); // Crear un Object URL a partir del blob
        // setRecordBlobLink(audioUrl);
       
        setRecordBlobLink(recordedBlob.blobURL); // Establecer el enlace del blob grabado
        setIsRunning(false)
        
        // setVoice(false);
    };

    const [currentView, setCurrentView] = useState(SIDEBARMENU); // 'sidebar', 'messages', 'chat'
    const [isMobile, setIsMobile] = useState(false);
    const [selectedChat, setSelectedChat] = useState(null);
    const [selectedWaId, setSelectedWaId] = useState(null);


  // Función para manejar la selección en el SidebarMenu
    const handleSidebarSelect = (menuOption) => {
      console.log("handleSidebarSelect", menuOption)
      setCurrentView(menuOption); // Muestra la lista de mensajes
      setResponseMode(false);
      setSelectedMenuOption(null)
    };
  
    // Función para manejar la selección en el MessagesListWindow
    const handleMessageSelect = (wa_id) => {
      setSelectedWaId(wa_id)
      console.log("handleMessageSelect", wa_id)
      setSelectedChat(wa_id);
      setCurrentView(CHATMESSAGE); // Muestra la ventana de chat
      setResponseMode(false);
      setSelectedMenuOption(null)
    };

    const sendMessageToWaAPI = async (body) => {
      console.log("body", body);
      const endpoint = "/wa_api/out";
      const resultSenMessage = await fetchFromCloudFunction(endpoint, body, "POST");
      console.log("resultSenMessage", resultSenMessage);
    }

console.log("audioUrl", audioUrl)
  // Manejar el envío de mensajes
const handleSendMessagge = async () => {
  const isFirstMessagge = imagePreviewUrls.length === 1 || documentFiles.length === 1
  let firstMessaggeText =  isFirstMessagge ? textValue : ""
  try {

    if(audioUrl) {
      const audioBlob = await fetchAudioAsBlob(audioUrl);
      console.log(audioBlob)
      const audioFirebaseUrl = await uploadAudioToFirebase(audioBlob, `waChat/${selectedWaId}/audios/fileName${Date.now()}`)
      console.log("audioFirebaseUrl", audioFirebaseUrl);
      const body = {
        userData: { wa_id: selectedWaId },
        type: "audio",
        text: "",
        file_link: audioFirebaseUrl,
        template_content: null,
        interactive_content: null,
      };
      await sendMessageToWaAPI(body);
      return
    }

    if(textValue !== "" && imagePreviewUrls.length === 0 && documentFiles.length === 0) {
      const body = {
        userData: { wa_id: selectedWaId },
        type: "text",
        text: textValue,
        file_link: null,
        template_content: null,
        interactive_content: null,
      };
      await sendMessageToWaAPI(body);
      setTextValue("");
      toastFunction(toast, "success", "Mensaje enviado con exito", "", 1000);
    } else {
    // Procesar imágenes
      if (imagePreviewUrls.length > 0) {
        await Promise.all(
          imagePreviewUrls.map(async (imageUrl) => {
            const uploadedImageLink = await processSingleImage(imageUrl);
  
            if (uploadedImageLink) {
              // Enviar imagen
              const body = {
                userData: { wa_id: selectedWaId },
                type: "image",
                text: firstMessaggeText,
                file_link: uploadedImageLink,
                template_content: null,
                interactive_content: null,
              };
              if(isFirstMessagge) {
                setTextValue("");
                firstMessaggeText = ""
              } 
              await sendMessageToWaAPI(body);
              toastFunction(toast, "success", "Imagen enviada con exito", "", 1000);
            }
            return uploadedImageLink;
          })
        );
        // Limpiar el estado después de procesar
        setImagePreviewUrls([]);
      }
      // Procesar documentos
      if (documentFiles.length > 0) {
        await Promise.all(
          documentFiles.map(async (doc) => {
            const uploadedDocumentLink = await processSingleDocument(doc);
            if (uploadedDocumentLink) {
              // Enviar documento
              const body = {
                userData: { wa_id: selectedWaId },
                type: "document",
                text: "",
                file_link: uploadedDocumentLink,
                template_content: null,
                interactive_content: null,
              };
              await sendMessageToWaAPI(body);
              toastFunction(toast, "success", "Documento enviada con exito", "", 1000);
            }
            return uploadedDocumentLink;
          })
        );
        // Limpiar el estado después de procesar
        setDocumentFiles([]);
      }
    }
   
  } catch (error) {
    toastFunction(toast, "error", "Error enviando el mensaje ", error, 1000);
    console.error("Error processing files:", error);
  }
};

// Procesar la url del audio local a blob
const fetchAudioAsBlob = async (audioUrl) => {
  try {
    const response = await fetch(audioUrl);
    if (!response.ok) throw new Error("Error fetching the audio file");
    const blob = await response.blob(); // Convertir la respuesta a Blob
    return blob;
  } catch (error) {
    console.error("Error fetching audio:", error);
    return null;
  }
};

// Procesar el blob del audio 

const uploadAudioToFirebase = async (blob, fileName) => {
  try {
    const storageRef = ref(storage, fileName); // Ruta en Firebase Storage
    const snapshot = await uploadBytes(storageRef, blob); // Subir el archivo
    const downloadURL = await getDownloadURL(snapshot.ref); // Obtener la URL pública
    return downloadURL;
  } catch (error) {
    console.error("Error uploading audio:", error);
    return null;
  }
};
// Procesar una sola imagen
const processSingleImage = async (imageUrl) => {
  try {
    const fileName = `waChat/${selectedWaId}/images/image_${Date.now()}`;
    const storageRef = ref(storage, fileName);
    await uploadString(storageRef, imageUrl, "data_url");
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading image:", error);
    return null;
  }
};

// Procesar un solo documento
const processSingleDocument = async (doc) => {
  try {
    const cleanName = doc.name
      .replace(/[^a-zA-Z0-9.\-_]/g, "") // Eliminar caracteres no permitidos
      .replace(/\s+/g, "_") // Reemplazar espacios por guiones bajos
      .toLowerCase();

    const fileName = `waChat/${selectedWaId}/documents/${Date.now()}_${cleanName}`;

    // Obtener el archivo como Blob
    const response = await fetch(doc.url);
    const blob = await response.blob();

    // Subir el archivo a Firebase Storage
    const storageRef = ref(storage, fileName);
    await uploadBytes(storageRef, blob);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading document:", error);
    return null;
  }
};


  return (
    <ChatMenuContext.Provider 
      value={{
        currentView, setCurrentView,

        menuChatOptions,
        selectedMenuOption,
        setSelectedMenuOption,
        handleChatMessageMenu,
        chatMessagesExample,
        messageData,
        shortcuts,
        scrollToElementById,
        imagePreviewUrls, setImagePreviewUrls,
        documentFiles, setDocumentFiles,
        errorMessage, setErrorMessage,
        responseMode, setResponseMode,
        removeImage, removeDocument,
        recordBlobLink, setRecordBlobLink, 
        clearHandle, voice, setVoice,
        visibleMicrophoneHandler, setVisibleMicrophoneHandler,
        audioUrl, setAudioUrl, 
        selectDocuments, selectMedia,
        elapsedTime, setElapsedTime,
        isRunning, setIsRunning,
        loadingMediaDevices,


        startHandle, stopHandle, onStop,
        
        isMobile, setIsMobile, selectedChat, setSelectedChat, selectedWaId, setSelectedWaId, textValue, setTextValue,
        
        handleSidebarSelect, handleMessageSelect,handleSendMessagge,
        toast


      }}>
      {children}
    </ChatMenuContext.Provider>
  );
};

// Hook personalizado para usar el contexto
export const useChatMenu = () => {
  return useContext(ChatMenuContext);
};
