import { FaPencilAlt } from "react-icons/fa"
import styles from "./MessageListHeader.module.css"
import MessageListSearch from "./MessageListSearch/MessageListSearch"
import { IoChevronBackOutline } from "react-icons/io5";
import { SIDEBARMENU } from "../../utils/CRMconstants";
import { useChatMenu } from "../../ChatWindow/ChatMenuContext";

export default function MessageListHeader() {
    const {
        handleSidebarSelect
       } = useChatMenu();
       
    return (
        <div>
            <div className={styles.messageListHeader}>
                <button onClick={() => handleSidebarSelect(SIDEBARMENU)}>
                    <IoChevronBackOutline className={styles.goBackBtnIcon} />
                </button>
                <h2 className={styles.sidebarTitle}>Mensajes</h2>
                <button className={styles.sidebarPencilBtn}>
                    <FaPencilAlt className={styles.iconSidebarPencilBtn} />
                </button>
            </div>
            {/* Buscador de mensajes */}
            <MessageListSearch />
        </div>
    )
}