import {  useRef } from 'react';
import { useChatMenu } from '../ChatMenuContext';
import styles from './TextAreaInputMessage.module.css' 
import toastFunction from '../../../../utils/toastFunction';


// eslint-disable-next-line react/prop-types
const TextAreaInputMessage = ({  setIsNavigationActive, isDragging,  setShowShortcuts, setFilteredShortcuts, onDragLeave,onDragOver}) => {
  const textAreaRef = useRef(null); 
  const { shortcuts } = useChatMenu();

  const { setImagePreviewUrls, setDocumentFiles, errorMessage, setErrorMessage, textValue, setTextValue, toast, } = useChatMenu();

  const handleDrop = (event) => {
    event.preventDefault();
  
    const files = Array.from(event.dataTransfer.files); // Archivos arrastrados
    const newImagePreviews = [];
    const newDocuments = [];
    let error = false;
  
    files.forEach((file) => {
      const fileType = file.type;
  
      // Manejo de imágenes
      if (fileType.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          newImagePreviews.push(reader.result);
          // Solo actualizar el estado cuando se procesen todas las imágenes
          if (newImagePreviews.length === files.filter(f => f.type.startsWith('image/')).length) {
            setImagePreviewUrls((prev) => [...prev, ...newImagePreviews]);
          }
        };
        reader.readAsDataURL(file);
  
      // Manejo de documentos
      } else if (
        fileType === 'application/pdf' ||
        fileType.startsWith('application/vnd.openxmlformats-officedocument') ||
        fileType.startsWith('application/msword') || // Compatibilidad con .doc
        fileType.startsWith('text/plain') || // Compatibilidad con .txt
        fileType.startsWith('application/vnd.ms-excel') || // Compatibilidad con .xls
        fileType.startsWith('application/vnd.ms-powerpoint') // Compatibilidad con .ppt
      ) {
        newDocuments.push({ name: file.name, url: URL.createObjectURL(file) });
  
      // Archivo no válido
      } else {
        error = true;
      }
    });
  
    // Actualizar estado de documentos si se procesaron
    if (newDocuments.length > 0) {
      setDocumentFiles((prev) => [...prev, ...newDocuments]);
    }
  
    // Manejar errores
    if (error) {
      toastFunction(toast, "error", 'Tipo de archivo no admitido. Solo se permiten imágenes, videos y documentos', "PDF, DOC, DOCX, TXT, XLS, XLSX, PPT y PPTX.")
    } 
  };

  const handleTextAreaInput = (e) => {
    const value = e.target.value;
    setTextValue(value);

    if (value.startsWith("/")) {
      setShowShortcuts(true);
      const query = value.slice(1).toLowerCase();
      const filtered = shortcuts.filter((shortcut) =>
        shortcut.title.toLowerCase().includes(query)
      );
      setFilteredShortcuts(filtered);
      setIsNavigationActive(true); // Activa la navegación
    } else {
      setShowShortcuts(false);
      setFilteredShortcuts([]);
      setIsNavigationActive(false); // Desactiva la navegación
    }
  };

  const handleKeyDown = (event) => {
    const value = event.target.value;
    if (event && value.startsWith("/")) {
      // Detecta las teclas de navegación
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        event.preventDefault(); // Evita que el cursor salte en el textarea
      }
    }
  };
  
  
  return (
    <div className={styles.container}>
      <div 
          style={{width:"100%"}}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={handleDrop}        
        >
          <textarea
            onKeyDown={handleKeyDown} // Agrega esta función
            style={{width:"100%"}}
            ref={textAreaRef}
            value={textValue}
            className={`${styles.textArea} ${isDragging ? styles.textAreaDragging : ''}`}
            placeholder="Escribe / para ver atajos"
            onChange={handleTextAreaInput}
          />
      </div>
        {errorMessage && (
          <div className={styles.errorMessage}>
            {errorMessage}
            <button
              className={styles.closeButton}
              onClick={() => setErrorMessage('')}
            >
              X
            </button>
          </div>
        )}
      
    </div>
  );
};

export default TextAreaInputMessage;
