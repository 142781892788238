import styles from "./WhatsAppCRM.module.css";
import SidebarMenu from "./SidebarMenu/SidebarMenu.jsx";
import ChatWindow from "./ChatWindow/ChatWindow.jsx";
import MessagesListWindow from "./MessagesListWindow/MessagesListWindow.jsx";
import useResize from "../../hooks/useResize.js";
import { CHATMESSAGE, MESSAGESLIST, SIDEBARMENU } from "./utils/CRMconstants.js";
import {  useChatMenu } from "./ChatWindow/ChatMenuContext.jsx";

function WhatsAppCRM() {

  const {
    currentView, isMobile, setIsMobile,
   } = useChatMenu();

  // Estado para manejar en qué vista estamos en mobile
  useResize(setIsMobile);

  // Función para manejar la selección en el SidebarMenu

 
  return (
    <div className={styles.appContainer}>
      {!isMobile ? (
        // Para escritorio mostramos los 3 componentes al mismo tiempo
        <>
          <SidebarMenu />
          <MessagesListWindow />
          <ChatWindow  />
        </>
      ) : (
        // Para mobile mostramos según la vista actual
        <>
          {currentView === SIDEBARMENU && <SidebarMenu  />}
          {currentView === MESSAGESLIST && <MessagesListWindow  />}
          {currentView === CHATMESSAGE && <ChatWindow />}
        </>
      )}
    </div>

  );
}

export default WhatsAppCRM;
