import { useSearchParams } from "react-router-dom";
import Products from "../Products/Products.jsx";
import { useEffect, useRef, useState } from "react";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import SecondaryButton from "../../Components/SecondaryButton/SecondaryButton.jsx";
import MainButton from "../../Components/MainButton/MainButton.jsx";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext.jsx";
import { Messages } from "primereact/messages";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js";
import toastFunction from "../../utils/toastFunction.js";
import { Toast } from "primereact/toast";
import useShopifyRequest from "../../hooks/useShopifyRequest.js";
import CustomConfirmDialog from "../../Components/OverlayComponents/CustomConfirmDialog/CustomConfirmDialog.jsx";

export default function Catalog() {
    const [searchParams] = useSearchParams();
    const catalogId = searchParams.get('catalogId');
    const msgsChip = useRef(null);
    const [messagesShown, setMessagesShown] = useState(false);
    const { t } = useTranslation("Catalog");
    const { catalogData, userData, userSellerOwnerData } = useAuthContext();
    const { updateDocument } = useFirebaseCRUD();

    const { waUrlHook } = useGlobalContext();
    const userOwnerData = userSellerOwnerData || userData;
    const [isCatalogOwner, setIsCatalogOwner] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    const [visibleProducts, setVisibleProducts] = useState(catalogData?.visible_products || []);
    const { toast } = useShopifyRequest();
    
    const [visibleConfirmShowAllProducts, setVisibleConfirmShowAllProducts] = useState(false);

    const disabledAddToCartBtn = userOwnerData && catalogData && catalogData?.id === catalogId && userOwnerData?.business_id === catalogData?.business_id
    
    const setCustomerView = () => {
        setIsCatalogOwner((prevState) => !prevState);
    };

    const addMessage = () => {
        if (msgsChip.current) {
            msgsChip.current.show([
                {
                    severity: "warn",
                    summary: "Esto es una vista previa. Tus clientes no verán tus ganancias",
                    detail: t(""),
                    sticky: true,
                    closable: false,
                },
            ]);
        }
    };

    const catalogIdValue = catalogData?.id || catalogId;
    const sellerCatalogUrl = `https://supplaii.com/catalog/?catalogId=${catalogIdValue}`;

    const shareCatalog = async () => {
        const message = `Hola, te comparto mi catalogo \n\n *${sellerCatalogUrl}*`;
        waUrlHook("withoutPhone", null, message);
    }

    const handleToggleHiddenProduct = async (productId) => {
        console.log("productId", catalogId, productId);
    
        setVisibleProducts((prevVisibleProducts) => {
            // Copiamos el objeto actual de visibleProducts
            const updatedVisibleProducts = { ...prevVisibleProducts };
    
            // Verificamos si el producto está actualmente visible
            if (updatedVisibleProducts[productId]) {
                // Si está visible, lo ocultamos
                updatedVisibleProducts[productId] = false;
            } else {
                // Si está oculto, lo hacemos visible
                updatedVisibleProducts[productId] = true;
            }
    
            // Actualizamos el documento en Firestore
            updateDocument("catalogs", catalogId, { visible_products: updatedVisibleProducts })
                .then(() => {
                    console.log("Documento actualizado exitosamente");
                })
                .catch((error) => {
                    console.error("Error actualizando el documento:", error);
                });
    
            // Retornamos el objeto actualizado para actualizar el estado
            return updatedVisibleProducts;
        });
    };

    const saveHiddenProducts = async () => {
        try {
            // Primero, obtenemos el documento actual para saber el estado de `show_all_products`
            const currentShowAllProductos = catalogData?.show_all_products;
    
            // Invertimos el valor booleano (toggle)
            const newShowAllProductos = !currentShowAllProductos;
    
            // Actualizamos el documento en Firestore con el nuevo valor
            const resultUpdateCatalog = await updateDocument("catalogs", catalogId, { show_all_products: newShowAllProductos });
    
            // Mostrar notificación basada en el resultado
            if (resultUpdateCatalog) {
                toastFunction(toast, "success", "Catálogo actualizado con éxito", "", 3000);
            } else {
                toastFunction(toast, "error", "No pudimos actualizar tu catálogo", "", 3000);
            }
        } catch (error) {
            console.error("Error al actualizar el catálogo:", error);
            toastFunction(toast, "error", "Ocurrió un error al actualizar el catálogo", "", 3000);
        }
    };
  useEffect(() => {
    setVisibleProducts(catalogData?.visible_products)
    if (catalogData && userOwnerData && catalogData.id === catalogId && userOwnerData.business_id === catalogData.business_id) {
        setIsCatalogOwner(true);
    }
    setIsMounted(true);
    }, [ userOwnerData, catalogData, catalogId]);

    useEffect(() => {
        if (isMounted && !messagesShown && userOwnerData && catalogData && catalogData?.id === catalogId && userOwnerData?.business_id === catalogData?.business_id) {
            const delay = setTimeout(() => {
                addMessage();
                setMessagesShown(true);
            }, 100);
            return () => clearTimeout(delay);
        }
    }, [isMounted, messagesShown,  catalogId, userOwnerData]);

    return (
        <div>
            <div className="fixed z-10 top-12">
                <Messages ref={msgsChip} />
            </div>
            {isMounted && (
                <div className={ userOwnerData && `mb-28`}>
                    <Products 
                        isCatalog={true} 
                        isCatalogOwner={isCatalogOwner} 
                        disabledAddToCartBtn={disabledAddToCartBtn} 
                        handleToggleHiddenProduct={handleToggleHiddenProduct}
                        visibleProducts={visibleProducts}
                    />
                </div>
            )}
          { userOwnerData && catalogData && catalogData?.id === catalogId && userOwnerData?.business_id === catalogData?.business_id && (  <div className="fixed-footer grid gap-1">
                <MainButton
                    type={"button"}
                    label={t("shareCatalog")}
                    onClick={shareCatalog}
                    // disabled={globalLoading}
                    // loading={globalLoading}
                />
                <SecondaryButton
                    floatLabel={true}
                    onClick={setCustomerView}
                    type={"button"}
                    label={isCatalogOwner ? t("¿Como lo ven mis clientes?") :  t("¿Configurar mi catalogo?")}
                    // disabled={globalLoading}
                    // loading={globalLoading}
                />
                
                <SecondaryButton
                    floatLabel={false}
                    onClick={() => setVisibleConfirmShowAllProducts(true)}
                    type={"button"}
                    label={ catalogData?.show_all_products ? t("Ocultar productos") :  t("Incluir todos los productos")}
                    // disabled={globalLoading}
                    // loading={globalLoading}
                    />
            </div>)}
        <Toast ref={toast} />

        <CustomConfirmDialog
          visible={visibleConfirmShowAllProducts}
          onHide={() => setVisibleConfirmShowAllProducts(false)}
          header={catalogData?.show_all_products ?  t("confirmDialog-confirmHiddenProducts.header") : t("confirmDialog-confirmShowProducts.header")}
          message={catalogData?.show_all_products ? t("confirmDialog-confirmHiddenProducts.message") : t("confirmDialog-confirmShowProducts.message")}
          icon="pi pi-question-circle"
          accept={() => saveHiddenProducts()}
          reject={() => setVisibleConfirmShowAllProducts(false)}
          acceptLabel={catalogData?.show_all_products ? t("confirmDialog-confirmHiddenProducts.acceptLabel"): t("confirmDialog-confirmShowProducts.acceptLabel") }
          rejectLabel={catalogData?.show_all_products ? t("confirmDialog-confirmHiddenProducts.rejectLabel"): t("confirmDialog-confirmShowProducts.acceptLabel")}
      />
        </div>
    );
}
