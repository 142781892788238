import styles from "./MessagesListWindow.module.css"
import MessageListHeader from "./MessageListHeader/MessageListHeader";
import MessageList from "./MessagesList/MessagesList";

export default function MessagesListWindow() {
 
    return (
      <div className={styles.menuListBar}>
        <MessageListHeader />
        <MessageList />
      </div>
    );
  }
  
  


