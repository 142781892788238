import { FaCog, FaComment } from 'react-icons/fa';
import styles from './MenuList.module.css'; // Asegúrate de ajustar la ruta del archivo CSS
import { MESSAGESLIST } from '../../utils/CRMconstants';
import { useChatMenu } from '../../ChatWindow/ChatMenuContext';

const MenuList = () => {
  
  const {
    handleSidebarSelect
   } = useChatMenu();
   
  return (
    <ul className={styles.menuList}>
      <li onClick={() => handleSidebarSelect(MESSAGESLIST)}>
        <FaComment className={styles.icon} />
        <span>Mensajes</span>
      </li>
      
      <li>
        <FaCog className={styles.icon} />
        <span>Configuración</span>
      </li>
    </ul>
  );
};

export default MenuList;
