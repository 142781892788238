import WhatsAppCRM from "../../Components/WhatsAppCRM/WhatsAppCRM"
// import ReactRecorder from "./ReactRecorder";

export default function WhatsAppCRMpage(){
    return (
        <WhatsAppCRM />
        // <div className=' bg-slate-800 h-[100vh] pt-10 text-white ' >
        //     <ReactRecorder />
        //     </div> 
    )
}

//  WhatsAppCRM;