import styles from "./ChatWindow.module.css"

import { ChatAudioMessage, ChatImageMessage, ChatMessageText, ChatVideoMessage } from "./ChatMessagges/ChatMessages";
import ChatInputMessage from "./ChatInputMessage/ChatInputMessage";
import ChatHeader from "./ChatHeader/ChatHeader";
import { useChatMenu } from "./ChatMenuContext";
import { useEffect, useRef } from "react";

// eslint-disable-next-line react/prop-types
export default function ChatWindow() {

  const { chatMessagesExample, responseMode  } = useChatMenu();
  const messagesEndRef = useRef(null);


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // useEffect para hacer scroll hacia abajo al montar el componente
  useEffect(() => {
    scrollToBottom(); // Scroll inicial
    if (responseMode === false) {
      const intervalId = setInterval(() => {
        scrollToBottom();
      }, 100); // Intentar hacer scroll cada 100 ms
      // Limpiar el intervalo después de 1 segundo
      setTimeout(() => {
        clearInterval(intervalId);
      }, 1000);
    }


  }, []);
  
  const formatAndValidateDate = (time) => {
    if (typeof time !== "string") return "Fecha inválida"; // Valida que sea una cadena
  
    // Forzar interpretación local
    const [year, month, day] = time.split("-").map(Number);
    const parsedDate = new Date(year, month - 1, day); // Meses son 0 indexados
  
    // Verifica si la fecha es válida
    if (isNaN(parsedDate)) return "Fecha inválida";
  
    const result = parsedDate.toLocaleDateString("es-CO", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return result;
  };
  
  
  // Agrupa mensajes por fecha (YYYY-MM-DD)
  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      if (!(message.time instanceof Date)) {
        console.warn("Mensaje con fecha inválida:", message);
        return groups;
      }
      const dateKey = message.time.toISOString().split("T")[0]; // Formato YYYY-MM-DD
      if (!groups[dateKey]) groups[dateKey] = [];
      groups[dateKey].push(message);
      return groups;
    }, {});
  };

  const groupedMessages = groupMessagesByDate(chatMessagesExample);


  return (
    <div className={styles.chatWindowContainer}>
      <ChatHeader/>
      <div className={styles.chatMessagesList}>


<>
      {Object.keys(groupedMessages).map((dateKey) => (
        <div key={dateKey} className={styles.dateGroupMessageContainer}>
          {/* Contenedor de la fecha */}
          <div className={styles.chatDate}>
            <p>{formatAndValidateDate(dateKey)}</p>
          </div>
          {/* Renderiza los mensajes del grupo */}
          {groupedMessages[dateKey].map((message) => {
            switch (message.type) {
              case "text":
                return (
                  <ChatMessageText
                    key={message.id}
                    messageId={message.id}
                    avatarSrc={message.avatarSrc}
                    username={message.username}
                    time={message.time}
                    type={message.type}
                    messageText={message.messageText}
                    isSent={message.isSent}
                    isRepliying={message.isRepliying}
                    typeRepliying={message.typeRepliying}
                    messageTextRepliying={message.messageTextRepliying}
                    imgSrcRepliying={message.imgSrcRepliying}
                    videoSrcRepliying={message.videoSrcRepliying}
                    idMessageRepliying={message.idMessageRepliying}
                    audioDuration={message.audioDuration}
                  />
                );
              case "image":
                return (
                  <ChatImageMessage
                    key={message.id}
                    messageId={message.id}
                    avatarSrc={message.avatarSrc}
                    username={message.username}
                    time={message.time}
                    messageText={message.messageText}
                    imageSrc={message.imageSrc}
                    type={message.type}
                    isSent={message.isSent}
                    isRepliying={message.isRepliying}
                    typeRepliying={message.typeRepliying}
                    imgSrcRepliying={message.imgSrcRepliying}
                    videoSrcRepliying={message.videoSrcRepliying}
                    messageTextRepliying={message.messageTextRepliying}
                    idMessageRepliying={message.idMessageRepliying}
                    audioDuration={message.audioDuration}
                  />
                );
              case "audio":
                return (
                  <ChatAudioMessage
                    key={message.id}
                    messageId={message.id}
                    avatarSrc={message.avatarSrc}
                    username={message.username}
                    type={message.type}
                    time={message.time}
                    audioSrc={message.audioSrc}
                    isSent={message.isSent}
                    messageTextRepliying={message.messageTextRepliying}
                    idMessageRepliying={message.idMessageRepliying}
                    audioDuration={message.audioDuration}
                  />
                );
              case "video":
                return (
                  <ChatVideoMessage
                    key={message.id}
                    messageId={message.id}
                    avatarSrc={message.avatarSrc}
                    username={message.username}
                    time={message.time}
                    messageText={message.messageText}
                    type={message.type}
                    videoSrc={message.videoSrc}
                    isSent={message.isSent}
                    isRepliying={message.isRepliying}
                    typeRepliying={message.typeRepliying}
                    videoSrcRepliying={message.videoSrcRepliying}
                    imgSrcRepliying={message.imgSrcRepliying}
                    messageTextRepliying={message.messageTextRepliying}
                    idMessageRepliying={message.idMessageRepliying}
                    audioDuration={message.audioDuration}
                  />
                );
              default:
                return null;
            }
          })}
        </div>
      ))}
    </>
      {/* Este elemento invisible se utiliza para hacer scroll hasta el final */}          
      <div ref={messagesEndRef}  />
     </div>
      
        <ChatInputMessage />

      </div>
    );
  }

