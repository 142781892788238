/* eslint-disable react/prop-types */
'use client'
import { useState, useEffect, useRef } from 'react'
import styles from './SocialCausesCard.module.css';
import MainButton from '../MainButton/MainButton';
import useIntersectionVideoPlayer from './useIntersectionVideoPlayer';

const SocialCausesCard = ({  setSelectedCause, setVisibleCauseModal,  index, cause, autoplay, hasEditCause, setHasEditCause}) => {
  const video = useRef(null);
  const [player, setPlayer] = useState(null);

  const {playing, handlePlay, muted, toggleMuted} = useIntersectionVideoPlayer(video, player, index);
  

  useEffect(() => {
    if (window.YT) {
      const playerInstance = new window.YT.Player(`youtube-player-${index}`, {
        height: '313',
        width: '311',
        videoId: `${cause.video_id}`,
        playerVars: {
          controls: 0,
          showinfo: 0,
          modestbranding: 1,
          loop: 1,
          playlist: `${cause.video_id}`,
        },
        events: {
          'onReady': (event) => {
            setPlayer(event.target);
            event.target.mute();
            if (autoplay) {
              event.target.playVideo();
            }
          },
        },
      });
    }
  }, [window?.YT, cause]);

  const [borderColor, setBorderColor] = useState(cause.border_color);

  useEffect(() => {
    setBorderColor(cause.border_color);
  }, [cause.border_color]);  

  const openStoreCauseModal = (cause) => {
    setHasEditCause(true);
    setSelectedCause(cause)
    setVisibleCauseModal(true)
  }


  return (
    <div className={`flex-none w-full relative h-full`}>
      <div
        id={`youtube-player-${index}`}
        className={`${styles.video} border-8 rounded-t-2xl w-full `}
        style={{borderColor: `#${borderColor}`}}
        ref={video}
      >
      </div>
      <div
        className={`absolute top-0 right-0 px-3 py-1 rounded-bl-lg rounded-tr-lg text-sm max-w-40 w-full `}
        style={{backgroundColor: `#${borderColor}`}}
      >
        <p
          className={`text-sm truncate font-semibold`}
          style={{color: `#${cause.text_border_color}`}}
        >
          {cause.tab_text}
        </p>
      </div>
      {muted && ( // Mostrar el texto solo si el autoplay está activado, es el primer video y no está en modo silencio
        <div className={styles.soundControl} onClick={toggleMuted}>
          🔊 Activar Audio
        </div>
      )}
      <div className="p-5 border bg-white border-gray-200 shadow-md rounded-b-2xl w-full h-full">
        <div className="flex gap-4 my-3">
          <img src={cause.icon_svg_url} alt={cause.title} width={70} height={70} />
          <div>
            <h5 className="font-bold text-xl line-clamp-1">{cause.title}</h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 line-clamp-3">
              {cause.description}
            </p>
          </div>
        </div>
       {cause || hasEditCause ? (<MainButton label="Editar causa" type="button" onClick={() => openStoreCauseModal(cause)} />) 
          : ( <MainButton label="Apoyar esta causa" type="button" onClick={() => console.log("Clic en causa ==> ", cause.title)} />)}
      </div>

     
    </div>
  )
}

export default SocialCausesCard