import { useEffect, useState, useRef } from "react";

export default function useIntersectionVideoPlayer(video, player, index) {
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);
  const observerRef = useRef(null);

  useEffect(() => {
    const options = {
      root: document.querySelector('main'),
      rootMargin: '0px',
      threshold: 0.6,
    };

    // Crear un nuevo IntersectionObserver
    observerRef.current = new window.IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const { target, isIntersecting } = entry;
        target._handleIntersect(isIntersecting); // Manejar la intersección
      });
    }, options);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (observerRef.current && video.current) {
      // Buscar el iframe específico generado por la API de YouTube
      const iframe = document.getElementById(`youtube-player-${index}`);
      if (iframe) {
        observerRef.current.observe(iframe); // Observar el iframe del video específico

        // Función que se ejecuta cuando el iframe entra o sale de la vista
        iframe._handleIntersect = (isIntersecting) => {
          if (player) {
            if (isIntersecting) {
              player.playVideo();
              setPlaying(true);
            } else {
              player.pauseVideo();
              setPlaying(false);
            }
          }
        };
      }
    }
  }, [video.current, player, index]);

  const handlePlay = () => {
    if (playing) {
      player.pauseVideo();
    } else {
      player.playVideo();
    }
    setPlaying(!playing);
  };

  const toggleMuted = () => {
    if (muted) {
      player.unMute();
      setMuted(false);
    } else {
      player.mute();
      setMuted(true);
    }
  };

  return {
    handlePlay,
    playing,
    toggleMuted,
    muted,
  };
}

// export default function useIntersectionVideoPlayer (video, player) {
//   const [playing, setPlaying] = useState(false);
//   const [muted, setMuted] = useState(true)

//   let observer = null;
//   useEffect(() => {
//     const options = {
//       root: document.querySelector('main'),
//       rootMargin: '0px',
//       threshold: 0.6
//     };
      
//     observer = new window.IntersectionObserver((entries) => {
//       entries.forEach(entry => {
//       const { target, isIntersecting } = entry
//       target._handleIntersect(isIntersecting)
//     })
        
//     }, options);
//   }, [])

//   useEffect(() => {
//     if (observer && video.current) {
//       observer.observe(video.current);
//       video.current._handleIntersect = (isIntersecting) => {
//         if (player) {
//           // Controlar reproducción en YouTube
//           isIntersecting ? player.playVideo() : player.pauseVideo();
//           setPlaying(isIntersecting);
//         }
//       };
//     }
//   }, [video.current, player]);
  

//   const handlePlay = () => {
//     if (playing) {
//       player.pauseVideo();
//     } else {
//       player.playVideo();
//     }
//     setPlaying(!playing);
//   };

//   const toggleMuted = () => {
//     if (muted === true) {
//       player.unMute();
//       setMuted(false);
//     } else {
//       player.mute();
//       setMuted(true);
//     }
//   };


//   return {
//     handlePlay,
//     playing,
//     toggleMuted,
//     muted
//   }

// };