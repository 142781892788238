import PropTypes from "prop-types";
import formatDate from "../../utils/formatDate.js";
import SecondaryButton from "../../Components/SecondaryButton/SecondaryButton.jsx";
import MainButton from "../../Components/MainButton/MainButton.jsx";
import toastFunction from "../../utils/toastFunction.js";
import { fetchFromCloudFunction } from "../../services/cloudFunctinons/fetchFromCloudFunction.js";
import generateLabelsPdf, { block_label_generator } from "../../utils/generateLabelsPdf.js";
import CustomConfirmDialog from "../../Components/OverlayComponents/CustomConfirmDialog/CustomConfirmDialog.jsx";
import { useState } from "react";

import { download_pdf_file } from "../../utils/downloadGuidePdf/pdfBase64.js";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase.js";

const ConsolidatedOrderCard = ({ toast, t, consolidatedOrder, productsData }) => {

  const [visibleConfirmPrintLabel, setVisibleConfirmPrintLabel] = useState(false);
  const [selectedConsolidatedProduct, setSelectedConsolidatedProduct] = useState();
  
  
  // console.log("consolidatedOrder", consolidatedOrder)
  const renderVariationInfo = (product) => {
    const renderArray = [];
    const auxiliaryRenderArray = [];
    const groupedVariations = {};
    const foundedProduct = productsData.find((dbProduct) => dbProduct.id === product.product_id);
    // Verificar si el producto tiene variaciones
    const hasVariations = foundedProduct?.product_with_variations;
    
    // Acumulador para productos sin variaciones
    let totalSkuReqQty = 0;
  
    // Procesar variaciones del producto
    product.variations.forEach((variation) => {
      if (!hasVariations) {
        totalSkuReqQty += variation.sku_req_qty; // Acumular la cantidad total
        return; // Saltar el resto del procesamiento si no tiene variaciones
      }
  
      if (foundedProduct?.options) {
        handleVariationsWithOptions(foundedProduct, variation, groupedVariations, auxiliaryRenderArray, renderArray);
      }
    });
  
    // Agregar la información de productos sin variaciones al renderArray si corresponde
    if (!hasVariations && totalSkuReqQty > 0) {
      renderArray.push(renderNoVariation(product, totalSkuReqQty));
    }
  
    // Acumular las cantidades de `sku_req_qty` de los que tienen el mismo `variation_id`
    Object.entries(groupedVariations).forEach(([key, variations]) => {
      const accumulatedVariations = {};
  
      variations.forEach((variation) => {
        if (accumulatedVariations[variation.variation_id]) {
          accumulatedVariations[variation.variation_id].sku_req_qty += variation.sku_req_qty;
        } else {
          accumulatedVariations[variation.variation_id] = { ...variation };
        }
      });
  
      groupedVariations[key] = Object.values(accumulatedVariations);
    });
    return renderCompleteComponent(product, groupedVariations, auxiliaryRenderArray, renderArray);
  };
  
  const renderNoVariation = (product, totalSkuReqQty) => (
    <div key={product.product_id}>
      <div className="text-lg mb-2 grid items-center">
        <div className="flex">
          <p className="font-semibold mr-2">Cantidad Total: &nbsp;</p>
          <p className="font-thin">{totalSkuReqQty}</p>
        </div>
      </div>
    </div>
  );
  

  const renderVariationNoSize = (variation) => (
    <div key={variation.id}>
      <div className="text-lg mb-2 grid items-center">
        <p className="font-bold">{variation?.sku_label}</p>
        <div className="flex">
          <p className="font-semibold mr-2">Cantidad: &nbsp;</p>
          <p className="font-thin">{variation?.sku_req_qty}</p>
        </div>
      </div>
    </div>
  );

  const handleVariationsWithOptions = (foundedProduct, variation, groupedVariations, auxiliaryRenderArray, renderArray) => {
    const isSize = foundedProduct.options.some((option) => option?.is_size);
    if (isSize) {
      handleAuxiliaryOptions(foundedProduct, auxiliaryRenderArray);
      handleSizeOptions(foundedProduct, variation, groupedVariations, renderArray);
    } else {
      renderArray.push(renderVariationNoSize(variation));
      handleAuxiliaryOptions(foundedProduct, auxiliaryRenderArray);
    }
  };

  const handleSizeOptions = (foundedProduct, variation, groupedVariations, renderArray) => {
    const foundedVariation = foundedProduct.variations?.find((v) => v.variation_id === variation.variation_id);
    if (foundedVariation) {
      const optionIds = foundedVariation.options;
      const names = getNames(foundedProduct, optionIds, false);
      const sizeNames = getNames(foundedProduct, optionIds, true);
      const key = names.join(", ");
      if (!groupedVariations[key]) groupedVariations[key] = [];
      groupedVariations[key].push({ ...variation, sizeNames });
    } else {
      renderArray.push(<div key={`${variation.id}-${variation.variation_id}`}>Variación no encontrada</div>);
    }
  };

  const handleAuxiliaryOptions = (foundedProduct, auxiliaryRenderArray) => {
    const optionTypes = foundedProduct.options
      .filter((option) => option.type !== "Talla" && option.type !== "TALLA") // Filtrar opciones que no sean de tipo "Talla" o "TALLA"
      .map((option) => option.type)
      .join(" / ");

    if (optionTypes) {
      auxiliaryRenderArray.push(optionTypes);
    }
  };

  const getNames = (product, optionIds, isSize) => {
    return product.options
      .filter((option) =>
        option.display_names.some((displayName) => optionIds.includes(displayName.option_id)) && option.is_size === isSize
      )
      .flatMap((option) =>
        option.display_names
          .filter((displayName) => optionIds.includes(displayName.option_id))
          .map((displayName) => displayName.name)
      );
  };

  const renderCompleteComponent = (product, groupedVariations, auxiliaryRenderArray, renderArray) => {
    return (
      <div key={product.product_id}>
        
        <div className="mt-4 mb-4">
          <p className="font-extrabold text-2xl">{product.product_label}</p>
          {auxiliaryRenderArray.length > 0 && (
            <p className="text-2xl mt-2 mb-4 font-thin">{auxiliaryRenderArray[0]} </p>
          )}
          {Object.entries(groupedVariations).map(([key, variations]) => (
            <div key={key} className="mb-4">
              <div className="mr-2">
                <div className="font-bold flex">
                  {key.split(", ").map((name, nameIndex, arr) => (
                    <p className="flex text-lg font-extrabold" key={nameIndex}>
                      {name}
                      {nameIndex < arr.length - 1 ? " / " : " "}
                    </p>
                  ))}
                </div>
                <div className="flex flex-wrap">
                  {variations.map((variation) => (
                    <div key={`${variation.id}-${variation.variation_id}`} className="text-lg mr-4">
                      <div className="border-b-2 border-black w-10">
                        {variation.sizeNames.map((name, nameIndex) => (
                          <p className="flex font-bold justify-center" key={`${nameIndex}-${variation.id}`}>
                            {name}
                          </p>
                        ))}
                      </div>
                      <p className="font-thin flex justify-center p-2 w-10">
                        {variation.sku_req_qty}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
          {renderArray}
        </div>
        <SecondaryButton
            style={{ marginTop: "12px" }}
            onClick={() => handlePrintProductLabel(product)}
            type={"button"}
            label={(consolidatedOrder.labels_generated.find((productId) => productId === product.product_id) ? "Qr ya ha sido impreso": " Imprimir Qr")}
            disabled={consolidatedOrder.labels_generated.find((productId) => productId === product.product_id) }
          />
      </div>
    );
  };

  const amountProducts = (products) => {
    const productMap = products.reduce((acc, product) => {
      if (!acc[product.product_id]) {
        acc[product.product_id] = {
          ...product,
          variations: [...product.variations]
        };
      } else {
        acc[product.product_id].variations.push(...product.variations);
      }
      return acc;
    }, {});

    return Object.values(productMap);
  };


  const handleConfirmPrintedLabel = async () => {
        await block_label_generator(selectedConsolidatedProduct.product_id, consolidatedOrder.id)
  }

  const agrupedProductsAndVariations = amountProducts(consolidatedOrder.products);
  const checkBase64InOrders = (array) => {
    // Verifica si el array es válido y no está vacío
    if (!Array.isArray(array) || array.length === 0) {
      return false;
    }
    // Verifica si todos los objetos tienen la clave shipping_destination.base64
    const allObjectsHaveBase64 = array.every(
      (obj) =>
        obj &&
        obj.shipping_guide_pdf &&
        obj.shipping_guide_pdf !== undefined &&
        obj.shipping_guide_pdf !== null &&
        obj.shipping_guide_pdf !== "" &&
        obj.guide_generated === true
    );

    // Si no todos los objetos tienen base64, muestra el toast y retorna false
    if (!allObjectsHaveBase64) {
      toastFunction(toast, "warn", t("Todas las ordenes deben tener guias generadas"), "", 4000);
      return false;
    }
    // Si todos los objetos tienen el base64 y la guia esta generada, retorna true
    return true;
  };
  console.log("consolidatedOrder", consolidatedOrder)

  const checkOrderIsConfirmed = (array) => {
    // Verifica si el array es válido y no está vacío
    if (!Array.isArray(array) || array.length === 0) {
      return false;
    }
    // Verifica si todos los objetos tienen la clave shipping_destination.city_code
    const allObjectsHaveCityCode = array.every((obj) => obj && obj.order_is_confirmed == true);
    // Si no todos los objetos tienen city_code, muestra el toast y retorna false
    if (!allObjectsHaveCityCode) {
      toastFunction(toast, "warn", t("Todas las ordenes deben estar confirmadas"), "", 4000);
      return false;
    }
    // Si todos los objetos tienen city_code, retorna true
    return true;
  };

  const handlePrintProductLabel = async (product) => {
    // setGlobalLoading(true);
    console.log("producto validacion", product)
    setSelectedConsolidatedProduct(product);
    const relatedOrderIds = consolidatedOrder?.related_orders_ids;

    if (!relatedOrderIds || relatedOrderIds.length === 0) {
      console.error("No related order IDs found");
      return;
    }
    try {
      await generateLabelsPdf({
        fromProducts: false,
        products: product,
        consolidated_order_id: consolidatedOrder.id,
        confirmFunction: () => setVisibleConfirmPrintLabel(true),
      })
 
    } catch (error) {
      console.error("Error al descargar las guías PDF:", error);
      // Puedes agregar aquí un mensaje de error si usas un toast o un modal
    }

    // setGlobalLoading(false);
  };

  const handleDownloadBatchPdfsGuides = async (product) => {
    // setGlobalLoading(true);
    console.log("producto validacion", product)
    setSelectedConsolidatedProduct(product);
    const relatedOrderIds = consolidatedOrder?.related_orders_ids;

    if (!relatedOrderIds || relatedOrderIds.length === 0) {
      console.error("No related order IDs found");
      return;
    }
    try {
      // Paso 1: Realiza el query a Firestore para obtener las órdenes
      const ordersRef = collection(db, "orders");
      const ordersQuery = query(ordersRef, where("id", "in", relatedOrderIds));
      const ordersSnapshot = await getDocs(ordersQuery);

      const orders = ordersSnapshot.docs.map(doc => ({...doc.data()}));
  
    
      // Paso 2: Realiza las validaciones necesarias
      if (checkBase64InOrders(orders) && checkOrderIsConfirmed(orders)) {
        for (const order of orders) {
          const titlePDF = `${order.order_number}_${order.customer_full_name}`;
          download_pdf_file(order.shipping_guide_pdf, titlePDF);
          // toastFunction(toast, "info", t("toast-summary.orderPDFDownload"), `${order.order_number} descargado`, 4000);
        }
        // toastFunction(toast, "success", t("toast-summary.downloadPDFSuccess"), `Cantidad: ${orders.length}`, 4000);
      } else {
        console.warn("Algunas órdenes no pasaron las validaciones.");
      }
    } catch (error) {
      console.error("Error al descargar las guías PDF:", error);
      // Puedes agregar aquí un mensaje de error si usas un toast o un modal
    }

    // setGlobalLoading(false);
  };

  const downloadExcel = () => {
    const excelUrl = consolidatedOrder?.excel_url;
  
    if (!excelUrl) {
      console.log("No hay URL de Excel disponible.");
      return;
    }
  
    // Abrir la URL en una nueva pestaña
    window.open(excelUrl, '_blank');
  };
  
 const createAndSaveExcel = async () => {
  const endpoint = "generateConsolidatedOrderReport";
  const body = {...consolidatedOrder};
  const quoteResult = await fetchFromCloudFunction(endpoint, body, "POST");
  console.log("quoteResult", quoteResult);
};

  return (
    <div className="bg-white rounded-xl shadow-xl p-4 flex flex-col justify-between h-full">
    <div>
      <p className="text-grey-100">Pedido consolidado</p>
      <p>Fecha :{formatDate(consolidatedOrder?.created_at)}</p>
      <p>ID :{consolidatedOrder?.id}</p>
      {agrupedProductsAndVariations.map((product) => renderVariationInfo(product))}
    </div>
    <div className="flex flex-col w-full mt-4">
      <SecondaryButton
            style={{ marginTop: "12px" }}
            onClick={handleDownloadBatchPdfsGuides}
            type={"button"}
            label={(" Descargar guias")}
          />
      <MainButton
        style={{ marginTop: "8px" }}
        type={"button"}
        label={consolidatedOrder?.excel_url ?  "Descargar Excel" : "Generar Excel" }
        // loading={globalLoading}
        onClick={consolidatedOrder?.excel_url ?  downloadExcel : createAndSaveExcel }
      />
    </div>
    <CustomConfirmDialog
          visible={visibleConfirmPrintLabel}
          onHide={() => setVisibleConfirmPrintLabel(false)}
          header={t("confirmDialog-printedProductLabel.header")}
          message={t("confirmDialog-printedProductLabel.message")}
          icon="pi pi-question-circle"
          accept={handleConfirmPrintedLabel}
          reject={() => setVisibleConfirmPrintLabel(false)}
          acceptLabel={t("confirmDialog-printedProductLabel.acceptLabel")}
          rejectLabel={t("confirmDialog-printedProductLabel.rejectLabel")}
      />
  </div>
  
  );
}

ConsolidatedOrderCard.propTypes = {
  productsData: PropTypes.array,
  consolidatedOrder: PropTypes.object,
  toast: PropTypes.object,
  t: PropTypes.object,
};

export default ConsolidatedOrderCard;
