/* eslint-disable react/prop-types */
import { useChatMenu } from "../../../ChatWindow/ChatMenuContext";
import styles from "./MessageListCard.module.css"
export default function MessageListCard({wa_id}) {
  const {
    handleMessageSelect
   } = useChatMenu();

    return (
      <li 
        className={styles.messageCardContainer}
        onClick={() => handleMessageSelect(wa_id)}
        >
        <div className={styles.contactAvatar}>
          <img src="contact-avatar.png" alt="contact" />
        </div>
        <div className={styles.contactInfo}>
          <h4>Nombre de contacto</h4>
          <p>Ultimo mensaje...</p>
        </div>
        <div className={styles.messageTime}>09:26 PM</div>
      </li>
    );
  }