/* eslint-disable react/prop-types */
import styles from './HorizontalScrollList.module.css';

function HorizontalScrollList({ children }) {
  return (
    <div className={styles.HorizontalScrollListContainer}>
        <div 
          style={{display:"flex" , marginBottom: "15px"}}>
          {children}
        </div>
    </div>
  );
}

export default HorizontalScrollList;
