import styles from "./SidebarMenu.module.css"
import UserProfileFooter from "./UserProfileFooter/UserProfileFooter.jsx";
import MenuList from "./MenuList/MenuList.jsx";


export default function SidebarMenu() {
    return (
      <div className={styles.sidebarMenu}>
        {/* Logo */}
        <div className={styles.logo}>
          <h2>Chatty</h2>
        </div>
        
        <MenuList />
        <UserProfileFooter/>
      </div>
    );
  }
