/**
 * Convierte un objeto Date en una cadena de texto en el formato especificado.
 *
 * @param {Date} date - El objeto Date a convertir.
 * @param {string} format - El formato en el que se desea la fecha ("dd/mm/aaaa" o "aaaa/mm/dd").
 * @returns {string} La fecha formateada como una cadena en el formato especificado.
 * @throws {Error} Lanzará un error si el argumento proporcionado no es un objeto Date válido.
 */
export const formatDateToString = (date, order) => {
    if (!(date instanceof Date)) {
        throw new Error("El argumento proporcionado no es un objeto Date válido.");
    }
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son de 0 a 11
    const day = String(date.getDate()).padStart(2, '0');
    console.log(order, day, year, month)
    switch (order) {
        case "DD/MM/YYY":
            return `${day}/${month}/${year}`;
        case "YYYY/MM/dd":
        default:
            return `${year}-${month}-${day}`;
    }
}

// Ejemplo de uso
// const date = new Date();
// const formattedDate1 = formatDateToString(date, "YYY/MM/DD");
// const formattedDate2 = formatDateToString(date, "DD/MM/YYY");
// const formattedDateDefault = formatDateToString(date); // Usa el formato por defecto "YYY/MM/DD"
// console.log(formattedDate2); // Salida: "27/06/2024" (o la fecha actual)
// console.log(formattedDateDefault); // Salida: "2024-06-27" (o la fecha actual)
