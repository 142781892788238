import { IoIosArrowDown, IoIosCamera, IoIosVolumeHigh } from "react-icons/io";
import styles from "./ChatMessages.module.css";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { TieredMenu } from "primereact/tieredmenu";
import { useRef, useState } from "react";
import { useChatMenu } from "../ChatMenuContext";
import { FaVideo } from "react-icons/fa";
import formatDateES from "../../../../utils/formatDateES";

export const ChatMessage = ({
  messageId,
  avatarSrc, 
  username, 
  time, 
  children, 
  isSent,
  messageText,
  videoSrc,
  audioSrc,
  imageSrc,
  isRepliying,
  typeRepliying,
  type,
  videoSrcRepliying,
  imgSrcRepliying,
  messageTextRepliying,
  idMessageRepliying,
  audioDuration

}) => {

  const [ responseMode, setResponseMode ] = useState(isRepliying ? true : false);

  const messageClass = isSent ? styles.sent : styles.received;
  const menu = useRef(null);


  const { menuChatOptions, selectedMenuOption, handleChatMessageMenu, scrollToElementById } = useChatMenu(); // Usa el hook
  const menuItems = menuChatOptions(selectedMenuOption);

  const renderReplyMedia = () => {
    switch (typeRepliying) {
      case "video":
        return (
          <video className={styles.thumbnail} muted>
            <source src={videoSrcRepliying} type="video/mp4" />
            Tu navegador no soporta el elemento de video.
          </video>
        );
      case "image":
        return <img src={imgSrcRepliying} alt="Contenido" className={styles.thumbnail} />;
      default:
        return null;
    }
  };
  return (
    <div id={messageId} className={`${styles.chatMessageContainer} ${messageClass}`}>
      <img src={avatarSrc} alt={username} className={styles.avatar} />
      <div className={styles.messageContent}>
        <div className={styles.messageHeader}>
          <span className={styles.username}>{username}</span>
          <div className={styles.messageHeaderTools}>
            <span className={styles.time}>{time ? formatDateES(time): ""}</span>
            <TieredMenu model={menuItems} popup ref={menu} breakpoint="767px" />
            <button
              className={`${styles.chatMenuOptionBtn} ${messageClass}`}
              onClick={(e) => handleChatMessageMenu(
              {
                username,
                messageId,
                messageText,
                videoSrc,
                audioSrc,
                imageSrc,
                type,
                audioDuration,
                event: menu.current.toggle(e)}
              )}
            >
              <IoIosArrowDown />
            </button>
          </div>
        </div>
        {responseMode === true && (
          <div >
                {responseMode && (
                    <div className={styles.chatInputContainerResponse} onClick={() => scrollToElementById(idMessageRepliying)}>
                      <div className={styles.responseHeader}>
                        <span className={styles.usernameResponse}>{username}</span>
                      </div>
                      <div className={styles.messageTextContainerResponse}>
                      <span className={styles.messageTextRepliying}>
                        {messageTextRepliying ||
                          (imgSrcRepliying ? (
                            <span>
                              <IoIosCamera className={styles.icon} />
                                Foto
                            </span>
                          ) : videoSrcRepliying ? (
                            <span>
                              <FaVideo className={styles.icon} /> 
                              Video
                            </span>
                          ) : (
                            ""
                          ))}
                      </span>
                        {renderReplyMedia()}
                        {audioSrc && (
                          <div className={styles.audioIconContainer}>
                            <IoIosVolumeHigh className={styles.icon} />
                          </div>
                        )}
                      </div>
                    </div>
                )}
              {/* Resto del contenido del mensaje */}
            </div>
          )}
    
        <div className={styles.messageText}>
          {type === "video" || type === "image" ?  messageText: "" }
          {children} {/* Aquí se inserta el contenido específico de cada tipo de mensaje */}
          <span className={styles.statusMessageCheck}>
            <IoCheckmarkDoneSharp className={styles.icon} />
          </span>
        </div>
      </div>
    </div>
  );
};

export const ChatMessageText = ({ audioDuration, type, avatarSrc, username, time, messageText, isSent, messageId, isRepliying, typeRepliying,  messageTextRepliying, imgSrcRepliying, idMessageRepliying }) => {
  return (
    <ChatMessage
      avatarSrc={avatarSrc}
      username={username}
      time={time}
      isSent={isSent} 
      messageId={messageId}
      messageText={messageText}
      isRepliying={isRepliying}
      typeRepliying={typeRepliying}
      messageTextRepliying={messageTextRepliying}
      type={type}
      imgSrcRepliying={imgSrcRepliying}
      idMessageRepliying={idMessageRepliying}
      audioDuration={audioDuration}
    >
      <span>{messageText}</span>
    </ChatMessage>
  );
};


export const ChatVideoMessage = ({ audioDuration, idMessageRepliying, type, avatarSrc, messageText, username, time, videoSrc, isSent, messageId, isRepliying,typeRepliying,videoSrcRepliying }) => {
  return (
    <>
      <ChatMessage 
        avatarSrc={avatarSrc}
        username={username}
        time={time}
        isSent={isSent}
        videoSrc={videoSrc}
        messageId={messageId}
        messageText={messageText}
        isRepliying={isRepliying}
        typeRepliying={typeRepliying}
        type={type}
        videoSrcRepliying={videoSrcRepliying}
        idMessageRepliying={idMessageRepliying}
        audioDuration={audioDuration}


      >
          {messageText}
          
          <video controls className={styles.video}>
            <source src={videoSrc} type="video/mp4" />
            Tu navegador no soporta el elemento de video.
          </video>
        </ChatMessage>
    
    </>
  );
};

export const ChatAudioMessage = ({ audioDuration, idMessageRepliying, type, avatarSrc, username, time, audioSrc, messageText, isSent, messageId}) => {
  return (
        
      
      <ChatMessage
        audioDuration={audioDuration}
        idMessageRepliying={idMessageRepliying}  type={type} avatarSrc={avatarSrc} username={username} time={time} isSent={isSent}  audioSrc={audioSrc} messageText={messageText}  messageId={messageId}>
      <div>
        <span>{messageText}</span>
        <audio controls className={styles.audioPlayer}>
          <source src={audioSrc} type="audio/mp3" />
          Tu navegador no soporta el elemento de audio.
        </audio>
      </div>
    </ChatMessage>
  );
};

export const ChatImageMessage = ({ audioDuration, idMessageRepliying, type, avatarSrc, username, time, imageSrc, isSent, messageId, messageText, isRepliying ,typeRepliying, imgSrcRepliying, videoSrcRepliying, messageTextRepliying}) => {
  return (
    <ChatMessage 
      audioDuration={audioDuration}
      avatarSrc={avatarSrc}
      username={username}
      time={time}
      isSent={isSent}
      messageId={messageId}
      messageText={messageText}
      imageSrc={imageSrc}
      isRepliying={isRepliying}
      typeRepliying={typeRepliying}
      imgSrcRepliying={imgSrcRepliying}
      videoSrcRepliying={videoSrcRepliying}
      type={type}
      idMessageRepliying={idMessageRepliying}
      >

      <img src={imageSrc} alt={`Image from ${username}`} className={styles.imageContent} />
    </ChatMessage>
  );
};
