// MODIFICADO PARA QUE GENERE UNA SOLA FILA POR PAGINA DE 3 ETIQUETAS.
// SE AÑADIO UNA PRIMERA ETIQUETA CON EL TEXTO "INICIO" PARA IDENTIFICAR VISUALMENTE EL INICIO DE CADA DOCUMENTO


import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import jsPDF from 'jspdf';
import printJS from 'print-js'
import QRCode from 'qrcode';
import { db } from '../firebase/firebase';

const etiquetaAncho = 32;
const etiquetaAlto = 25;
const espaciadoHorizontal = 3;
const espaciadoVertical = 3;
const margen = 1;
const margenInterno = 3; // Este es el margen interno de la etiqueta

export default async function generateLabelsPdf({fromProducts = false, products, confirmFunction}) {
    let title_label = null;
    let AUX_datos = []
    console.log("variation", products.variations)
    if (fromProducts === true) {
        products.forEach(product => {
            let groupedData = []
            title_label = product.product_label
            product.variations.map(variation => {
                groupedData.push({
                    SKU: variation.sku,
                    QtySKU: variation.sku_req_qty,
                    LabelSKU: variation.sku_label,
                    Options: variation.options,
                    Reference: title_label, // Agregamos la referencia aquí
                })
            })
            // Ordena solo las variantes de este producto
            groupedData = sortByField(groupedData, "LabelSKU")
            // Agrega la etiqueta 'INICIO' al principio de los datos de este producto
            // groupedData.unshift({
            //     SKU: 'INICIO',
            //     QtySKU: 1,
            //     LabelSKU: 'INICIO',
            //     Reference: title_label,
            // });
            // Añade los datos de este producto a los datos totales
            AUX_datos = AUX_datos.concat(groupedData)
        });
    } else {
        console.log(products)
        title_label = products.product_label
        // Agrega la etiqueta 'INICIO' solo una vez al principio de los datos
        // AUX_datos.push({
        //     SKU: 'INICIO',
        //     QtySKU: 1,
        //     LabelSKU: 'INICIO',
        //     Reference: title_label,
        // });
        products.variations.map(variation => {
            AUX_datos.push({
                SKU: variation.sku,
                QtySKU: variation.sku_req_qty,
                LabelSKU: variation.sku_label,
                Options: variation.options,
                Reference: title_label, // Agregamos la referencia aquí
            })
        })
    }
    let datos = AUX_datos.sort((a, b) => {
        // Extraer opción de tamaño (is_size: true)
        let sizeLabelA = a.Options.find(option => option.is_size)?.option_label || "";
        let sizeLabelB = b.Options.find(option => option.is_size)?.option_label || "";
        // Extraer opción no de tamaño (is_size: false)
        let nonSizeLabelA = a.Options.find(option => !option.is_size)?.option_label || "";
        let nonSizeLabelB = b.Options.find(option => !option.is_size)?.option_label || "";
        // Reglas de orden
        if (nonSizeLabelA && nonSizeLabelB) {
            // Si ambos tienen una opción no size, agrupar por nonSizeLabel y ordenar por sizeLabel
            let nonSizeComparison = nonSizeLabelA.localeCompare(nonSizeLabelB);
            if (nonSizeComparison !== 0) {
                return nonSizeComparison;
            }
            // Dentro del mismo grupo, ordenar por sizeLabel numéricamente si posible
            return sizeLabelA.localeCompare(sizeLabelB, undefined, { numeric: true });
        }
        if (sizeLabelA && sizeLabelB) {
            // Si solo tienen opciones size, ordenar por sizeLabel numéricamente
            return sizeLabelA.localeCompare(sizeLabelB, undefined, { numeric: true });
        }
        if (!sizeLabelA && !sizeLabelB) {
            // Si ninguno tiene sizeLabel, ordenar por LabelSKU
            return a.LabelSKU.localeCompare(b.LabelSKU);
        }
        // Manejo de casos donde uno tiene sizeLabel y el otro no
        return sizeLabelA ? -1 : 1;
    });

    //-------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------
    // con "datos" ya organizado, se procede a crear las etiquetas
    console.log("datos", datos)
    
    let x = margen, y = margen;
    let doc = new jsPDF('p', 'mm', [etiquetaAncho * 3 + espaciadoHorizontal * 2 + margen * 2, 297]);
    let etiquetasEnPagina = 0; // Añadimos un contador de etiquetas

    for (let i = 0; i < datos.length; i++) {
        let { SKU, QtySKU, LabelSKU, Options, Reference } = datos[i];
        console.log("validacion QR",  Options )
        console.log("validacion QR", SKU, QtySKU, LabelSKU, Options, Reference )
        if(SKU === 'INICIO'){
            doc.setFontSize(24).setFont("ariel", 'bold');
            let textOffsetX = x + (etiquetaAncho / 2);
            let textOffsetY = y + (etiquetaAlto / 2);
            doc.text('INICIO', textOffsetX, textOffsetY, {align: "center"});

            doc.setFontSize(10).setFont("ariel", 'bold');
            doc.text(Reference+"-->", textOffsetX, textOffsetY+5, {align: "center"});
        }

        let parte1 = "";
        let parte2 = "";
        parte1 = Options.filter(option => !option.is_size).map(option => option.option_label).join(" ");
        let sizeOption = Options.find(option => option.is_size);
        parte2 = sizeOption ? sizeOption.option_label : "";
        console.log("Validacion partes", parte1, parte2)
        for (let j = 0; j < QtySKU; j++) {
            if(SKU !== 'INICIO'){
                // INICIO QR::::::::::::::::::
                const imgData = await QRCode.toDataURL(SKU);
                const qrWidth = ((etiquetaAncho) / 2)* 1.1;
                doc.addImage(imgData, 'PNG', x+0.5, y+0.5, qrWidth, qrWidth, undefined, 'NONE');
                // FIN QR::::::::::::::::::
            
                // INICIO REFERENCE::::::::::::::::::
                doc.setFontSize(8).setFont(undefined, 'bold');
                let textWidthh = doc.getStringUnitWidth(Reference) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                let textOffsetXX = x + (etiquetaAncho - textWidthh) / 2;
                doc.text(Reference, textOffsetXX, y+1);  // ajusta el valor de 0.5 si es necesario
                doc.setFillColor(255);  // blanco
                doc.setTextColor(0);  // negro
                // FIN REFERENCE::::::::::::::::::
    
                // INICIO COLOR::::::::::::::::::
                doc.setFillColor(255);
                doc.setTextColor(0);
                let fontSize = 9;
                let lines = doc.splitTextToSize(parte1, etiquetaAncho - margenInterno, { fontSize: fontSize });
                doc.setFontSize(fontSize).setFont("ariel", 'light').setLineHeightFactor(0.7);
                doc.text(lines,  (x-0.5) + margenInterno, y + 6.3 * margenInterno);
                // FIN COLOR::::::::::::::::::
                
                // INICIO TALLA::::::::::::::::::
                doc.setFontSize(18).setFont("ariel", 'bold');
                let textOffsetX = x + qrWidth + 2 * margenInterno + ((qrWidth) / 2)*(-0.9);
                let textOffsetY = (y + margenInterno + fontSize) + 1.5;
                doc.text(parte2, textOffsetX, textOffsetY);
                // FIN TALLA::::::::::::::::::
            }

            x += etiquetaAncho + espaciadoHorizontal;
            etiquetasEnPagina++;

            if (etiquetasEnPagina >= 3) {
                etiquetasEnPagina = 0;
                x = margen;
                y = margen;
                if (j < QtySKU - 1 || i < datos.length - 1) {
                    doc.addPage();
                }
            }
        }
    }

    let aux_pdf = doc.output('blob');
    fileToBase64(aux_pdf, (aux_pdf_base64)=>{
        console.log(aux_pdf_base64)
        print_pdf_file_general(aux_pdf_base64, async () => confirmFunction())
    })
}

// FUNCIONES AUXILIARES -------------------------------------------------------------
// ----------------------------------------------------------------------------------

const sortByField = (items, field) => {
    let aux_items = [...items]
    let items_ordered= aux_items.sort((a,b)=>sortByIndex(a[field], b[field]))
    return items_ordered
}

const sortByIndex=(aPosition, bPosition)=>{
    if(aPosition<bPosition){
        return -1 //NOTE: a come first
    }
    if(aPosition>bPosition){
        return 1 //NOTE: b come first
    }
    return 0 //NOTE: a can come before b OR viceversa //Dont modify the position actually respect each other but it does repect the others elements in the array
}

const fileToBase64 = (file, callback) => {
    let reader = new FileReader();
    reader.onload = async (e) => {
        let binaryData = e.target.result;
        let base64String = btoa(
            new Uint8Array(binaryData)
                .reduce((data, byte) => data + String.fromCharCode(byte), "")
        );
        await callback(base64String);
    };
    reader.readAsArrayBuffer(file); // Usar ArrayBuffer en lugar de BinaryString
};
const print_pdf_file_general = (base64pdf, onPrintDialogCloseFunction) => {
    let button = document.createElement('button');
    button.type = "button";
    button.onclick = printJS({printable: base64pdf, type: 'pdf', base64: true, onPrintDialogClose: onPrintDialogCloseFunction});
    button.click();
    button.remove();
}
export const block_label_generator = async (product_id, consolidated_order_id) => {
    try {
        const consolidatedOrderRef = doc(db, "consolidated_orders", consolidated_order_id);
        
        await updateDoc(consolidatedOrderRef, {
            labels_generated: arrayUnion(product_id) // Agrega el product_id al array
        });

        console.log(`Etiqueta bloqueada para producto: ${product_id}`);
    } catch (error) {
        console.error(error);
        console.error("Ocurrió un error en -block_label_generator-");
    }
};


