
import { VscPinned } from 'react-icons/vsc';
import MessageListCard from './MessageListCard/MessageListCard';
import styles from './MessageList.module.css'; // Ajusta la ruta al archivo CSS si es necesario
import { TbMessage2 } from 'react-icons/tb';

const MessageList = () => {
  
  return (
    <div className={styles.messageListContainer}>
      <p className={styles.sectionMessageText}>
        <VscPinned className={styles.icon} />
        Mensajes fijos
      </p>
      <ul className={styles.messageList}>
        <MessageListCard wa_id={"3045926289"} />
        <MessageListCard wa_id={"3045926289"} />
        <MessageListCard wa_id={"3045926289"} />
      </ul>
      <p className={styles.sectionMessageText}>
        <TbMessage2 className={styles.icon} />
        Todos los mensajes
      </p>
      <ul className={styles.messageList}>
        <MessageListCard wa_id={"3045926289"} />
        <MessageListCard wa_id={"3045926289"} />
        <MessageListCard wa_id={"3045926289"} />
        <MessageListCard wa_id={"3045926289"} />
        <MessageListCard wa_id={"3045926289"} />
        <MessageListCard wa_id={"3045926289"} />
        <MessageListCard wa_id={"3045926289"} />
        <MessageListCard wa_id={"3045926289"} />
      </ul>
    </div>
  );
};

export default MessageList;
