import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import useShopifyRequest from "../../hooks/useShopifyRequest";
import { useTranslation } from "react-i18next";
import MainButton from "../MainButton/MainButton";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import { generateRandomId } from "../../utils/generateRandomId.JS";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog";
import DeleteButton from "../DeleteButton/DeleteButton";
import toastFunction from "../../utils/toastFunction";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db, storage } from "../../firebase/firebase.js";
import CustomInputNumber from "../FormComponents/CustomInputNumber/CustomInputNumber.jsx";
import CustomInputSwitch from "../FormComponents/CustomInputSwitch/CustomInputSwitch.jsx";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText.jsx";
import { deleteObject } from "firebase/storage";
import formatForSearch from "../../utils/formatForSearch.js";
import CustomMultiSelect from "../CustomMultiSelect/CustomMultiSelect.jsx";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Message } from 'primereact/message';

/* eslint-disable react/prop-types */

// Componente de formulario que permite encontrar si existe una vinculacion o vincular un producto de una tienda de shopify
const VinculateProductForm = ({ productData, userData, toast, onClose, onCloseDetailProductModal }) => {
  const { t } = useTranslation("Products");
  const { fetchShopifyProductConnection } = useShopifyRequest();
  const {
    getDocumentsByQuery,
    getDocumentById,
    updateDocument,
    getAllDocumentsFromSubcollection,
    updateSubcollectionDocument,
    createSubcollectionDocument,
    deleteSubcollectionDocument,
  } = useFirebaseCRUD();
  const [storeSelect, setStoreSelect] = useState(null);
  const [productSelect, setProductSelect] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const sellerStores = userData?.seller_stores ? userData?.seller_stores.filter((store) => store.platform.code !== 3) :  []
  const [shoppexStoreData, setShoppexStoreData] = useState();
  const [productHasVinculated, setProductHasVinculated] = useState(false);
  const [disabledProductAndStoreSelect, setDisabledProductAndStoreSelect] = useState(false);
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
  

  const relatedSyncedShoppexProductInitialValues = {
      id: generateRandomId(),
      synced_at: productData?.synced_at || new Date(),
      product_id: productData.id, // Id del producto ( ide del documento del producto en la coleccion "products")
      // TODO: Hereda el nombre para facilitar la vinculacion  
      tag_ids: productData?.tag_ids || [],
      vertical_video_src: productData?.vertical_video_src || "",
      custom_title: productData?.product_name|| "", // Titulo personalizado del producto
      custom_title_for_search: productData?.custom_title_for_search || formatForSearch(productData?.product_name),
      premium_service_fee: 0, // Tarifa de servicio premium (Usado si la tienda is_a_buying_club: true )
      basic_service_fee: 0, // Tarifa de servicio basica (seria la ganancia del seller)
      with_fixed_price: false, // Si el monto total que pagará el consumidor final por el producto es fijo (Significa que si el precio de proveedor cambia, el precio en la tienda no se verá afectado, si es false, entonces el precio en tienda se actualiza automáticamente si cambia el precio del proveedor ya que se sumará el supplier_price + basic_service_fee
      is_active: true, // Indicador de si el producto esta activo
      suggested_retail_price: productData?.suggested_retail_price||  0, // Precio sugerido de venta, se guarda para emparejar el producto correctamente
      fixed_price: productData?.suggested_retail_price, // Monto FIJO total que pagara el consumidor final. Usado si with_fixed_price: true)
      product_cost: productData?.commercial_partner_price_with_VAT_with_service_fee || productData?.product_cost || 0,
      comparative_price: productData?.comparative_price ||  0, // Precio de comparacion
      store_id: null,
      store_platform: storeSelect ? storeSelect.platform : null,
      shopify_product_data: null,
    };  

  const [ relatedSyncedShoppexProductValues, setRelatedSyncedShoppexProductValues ] = useState(relatedSyncedShoppexProductInitialValues)
  const [ selectedTags, setSelectedTags ] = useState([]);
  const [ premiumServiceFeeValue, setPremiumServiceFeeValue ] = useState(null);
  const [ comparativePriceValue, setComparativePriceValue ] = useState(null);

  const isFirstRender = useRef(true);

  // console.log("relatedSyncedShoppexProductValues", relatedSyncedShoppexProductValues)
  // console.log("productData", productData)
  // console.log("shoppexStoreData", shoppexStoreData)


  // Función para encontrar el producto vinculado en los documentos de tienda
  const findStoreAndVinculatedProductInStores = async (relatedSyncedId, sellerStores) => {
    
    // Si no hay tiendas, devolvemos un objeto vacío
    if (sellerStores.length === 0) {
      return {
        vinculatedStore: null,
        vinculatedProduct: null,
      };
    }
    
    // Función auxiliar para buscar un productId en una lista de strings (syncedList)
    const findSyncedProductInDocumentStoreData = async (storeId, relatedSyncedId) => {
      const relatedStoreProductsData = await getAllDocumentsFromSubcollection("stores", storeId, "related_products");
      if (relatedStoreProductsData) {
        const syncedProductFound = relatedStoreProductsData?.find((relatedProduct) => relatedProduct.id === relatedSyncedId);
        // console.log("syncedProductFound", syncedProductFound)
        return syncedProductFound || null; // Retorna el producto encontrado o null
      }
      
    };

    // Itera sobre cada tienda y verifica si el productId se encuentra en alguna de ellas
    for (const vinculatedStore of sellerStores) {
      const vinculatedProduct = await findSyncedProductInDocumentStoreData(vinculatedStore.id, relatedSyncedId); // Esperamos la promesa
      if (vinculatedProduct) {
        return {
          vinculatedStore,
          vinculatedProduct,
        };
      }
    }

    // Retornamos un objeto con propiedades null si no se encontró el producto
    return {
      vinculatedStore: null,
      vinculatedProduct: null,
    };
  };

  const handleDeleteVinculation = async () => {
    setGlobalLoading(true)
      if (storeSelect?.platform?.code === 2 && !productSelect) {
        toastFunction( toast, "info", "Para eliminar una vinculacion con Shopify es necesario seleccionar el producto", "", 3000);
          return
      }
  
      if (storeSelect?.platform?.code === 1) { // SI la tienda seleccionada es de shoppex 
        // Eliminamos el fixed price al igual que los objetos de la vinculacion
          const newFixedprices = {
            ...shoppexStoreData.fixed_prices,
          }
          delete newFixedprices[relatedSyncedShoppexProductValues.product_id]
          await updateDocument("stores", shoppexStoreData.id, {fixed_prices: newFixedprices});

          console.log("relatedSyncedShoppexProductValuesrelatedSyncedShoppexProductValues", relatedSyncedShoppexProductValues)
          if (relatedSyncedShoppexProductValues){
            for (const section of relatedSyncedShoppexProductValues.sections) {
              if (section.media_path) {
                try {

                  await deleteObject(storage, section.media_path);
                } catch (error) {
                  console.error(error)
                }
              }
            }
          }
          toastFunction( toast, "success", "Vinculacion eliminada con exito", "", 3000);
      }
      // console.log("validacion", shoppexStoreData, storeSelect, relatedSyncedShoppexProductValues )
      await deleteSubcollectionDocument("stores", shoppexStoreData?.id || storeSelect?.id , "related_products", relatedSyncedShoppexProductValues.id);
  
   
  

    setTimeout(() => {
      onClose();
      onCloseDetailProductModal()
      setGlobalLoading(false)      
    }, 500);
  };

  const shoppexProductHasInStore = async (storeId, productId) => {
    try {
      // Construir la referencia a la subcolección "related_products" dentro de "stores"
      const relatedProductsRef = collection(db, "stores", storeId, "related_products");
      
      // Crear una consulta para buscar el documento donde "product_id" sea igual al productId
      const q = query(relatedProductsRef, where("product_id", "==", productId));
      
      // Ejecutar la consulta
      const querySnapshot = await getDocs(q);
  
      // Si hay algún documento que coincida, el producto existe
      return !querySnapshot.empty;
    } catch (error) {
      console.error("Error al verificar si el producto existe en la tienda:", error);
      return false;
    }
  };

  const shopifyProductHasInStore = async (storeId, productId) => {
    try {
      // Validación de storeId y productId
      if (!storeId || !productId) {
        console.error("storeId o productId no proporcionado.");
        return false;
      }
  
      // Obtener todos los productos relacionados de la subcolección "related_products"
      const relatedStoreProductsData = await getAllDocumentsFromSubcollection("stores", storeId, "related_products");
  
      // Validar que la respuesta sea un arreglo
      if (!Array.isArray(relatedStoreProductsData)) {
        console.error("Los datos de productos relacionados no son un arreglo.");
        return false;
      }
  
      // Validar que la subcolección tenga productos
      if (relatedStoreProductsData.length === 0) {
        console.log("No se encontraron productos en la subcolección.");
        return false;
      }
  
      // Buscar si el producto de Shopify está sincronizado en la tienda
      const findedSyncedProduct = relatedStoreProductsData.find((syncedProduct) => {
        // Validar que `syncedProduct.shopify_product_data` exista y tenga un ID
        return syncedProduct?.shopify_product_data?.id === productId;
      });
  
      // Retornar el producto encontrado o `false` si no existe
      if (findedSyncedProduct) {
        return findedSyncedProduct;
      } else {
        console.log(`El producto con ID ${productId} no está sincronizado en la tienda.`);
        return false;
      }
  
    } catch (error) {
      // Manejo de errores
      console.error("Error al verificar si el producto existe en la tienda:", error);
      return false;
    }
  };

  // Manejar la selección de una tienda
  const handleStoreSelect = async (store) => {
    setStoreSelect(store);
    const shoppexStoreData = await getDocumentById("stores", store.id)
    setShoppexStoreData(shoppexStoreData);

    const hasIdInStore = await shoppexProductHasInStore(store.id, productData.id)
    setGlobalLoading(true);
    switch (store?.platform?.code) {
      case 1:       // Si la plataforma es shoppex, al elegir el producto calculamos y actualizamoslos valores en base al producto seleccionado
        if (hasIdInStore) {
          setProductHasVinculated(true);
          toastFunction(toast, "warn", `${t("toast-summary.theProduct")}${productData.product_name} ${t("toast-summary.productIsVinculated")}`,  "", 4000);
        } else {
         
          setProductHasVinculated(false);
          toastFunction(toast, "success", `${t("toast-summary.theProduct")}${productData.product_name} ${t("toast-summary.productIsNotVinculated")}`,  "", 4000);
          setRelatedSyncedShoppexProductValues((prevValues) => ({
            ...prevValues,
            fixed_price: productData?.suggested_retail_price,
            basic_service_fee: productData.suggested_retail_price - (productData?.commercial_partner_price_with_VAT_with_service_fee),
            // se agrega el precio sugerido para poder emparejar los productDataos despues
            suggested_retail_price: productData.suggested_retail_price,
          }));
        }

        break;
      case 2:
        await fetchShopifyProductsData(store);
        break;
      default:
        console.log("Plataforma no especificada");
        break;
    }
    setGlobalLoading(false);
  };

  const fetchShoppexSellerProducts = async (businessId) => {
    const queryRef = query(collection(db, "products"), where("commercial_partner_id", "==", businessId));
    const resultFetchShoppex =  await getDocumentsByQuery(queryRef);
    const extractedProductShoppexProperties = resultFetchShoppex.map((product) => {
      const { product_name, id, commercial_partner_price_with_VAT, suggested_retail_price } = product;
      return { name: product_name, id: id, commercial_partner_price_with_VAT, suggested_retail_price };
    });

    // Si se selecciona un producto del cual el usario es dueño no podra vincularlo con el mismo producto
     if (userData?.business_id === productData?.commercial_partner_id) {
       const filteredExtractedDataWhithoutSelectedShoppexProduct = extractedProductShoppexProperties?.filter((shoppexProduct) => {
         return shoppexProduct?.id !== productData?.id
       })
       setProductsData(filteredExtractedDataWhithoutSelectedShoppexProduct);
     }else {
       setProductsData(extractedProductShoppexProperties);

     }

  };

  // Manejar la selección de un producto Shopify
  const handleProductSelect = async (product) => {
    // Verificar si el producto ya está vinculado a la tienda seleccionada

    const hasIdInStore = await shopifyProductHasInStore(storeSelect.id, product.id)
    setProductSelect(product);
    console.log(hasIdInStore)
    if (hasIdInStore) {
      setProductHasVinculated(true);
      toastFunction(toast, "warn", `${t("toast-summary.theProduct")}${product.name} ${t("toast-summary.productIsVinculated")}`,  "", 4000);
    } else {
      setProductHasVinculated(false);
      toastFunction(toast, "success", `${t("toast-summary.theProduct")}${product.name} ${t("toast-summary.productIsNotVinculated")}`,  "", 4000);
    }
  
  };

  // Obtener productos de Shopify para una tienda
  const fetchShopifyProductsData = async (shopifyStore) => {
    setGlobalLoading(true);
    const body = {
      shopifyUrl: shopifyStore.store_url,
      tokenId: shopifyStore.product_sync_shopify_app_token,
    };
    try {
      // Realizar la solicitud para obtener productos de Shopify
      const resultFetchProductsShopify = await fetchShopifyProductConnection(
        body
      );
    
      // Actualizar el estado con los productos extraídos
      const newArrayWithProductUsableProperties = extractProductShopifyProperties(
        resultFetchProductsShopify?.data.products
      );

      setProductsData(newArrayWithProductUsableProperties);

      if (resultFetchProductsShopify.status === 200) {
        toastFunction(toast, "success", t("succefullProductsConnection"),  "", 4000);
      }
      if (resultFetchProductsShopify?.data.productsLength === 0) {
        toastFunction(toast,  "warn", t("noProductsInShopify"),  "", 4000 );
      }
      return true;
    } catch (error) {
      setProductsData([]);
      setProductHasVinculated(true);
      console.error(error);
      toastFunction(toast, "error", t("toast-summary.errorProductsConnection"), "", 4000 );
      return false;
    }
  };
  // Extraer propiedades necesarias de los productos
  const extractProductShopifyProperties = (products) => {
    const extractedData = products.map((product) => {
      const { title, id } = product;
      return { name: title, id: id }; // Cambia los nombres de las claves según tu preferencia
    });
    return extractedData;
  };

  // Función para vincular un producto de Shopify a una tienda
  const vinculateProduct = async (e) => {
    e.preventDefault();
    setGlobalLoading(true);
    
    const { categories_ids } = productData || {};
    const { platform, is_a_buying_club } = storeSelect || {};
    const isShoppexStore = platform?.code === 1;
    
    let resultVinculation;
    let newrelatedSyncedProductValues = {
      ...relatedSyncedShoppexProductValues,
      comparative_price: comparativePriceValue,
      categories_ids,
      store_id: storeSelect.id,
    };
    
    if ( platform.code === 2 ) {

      newrelatedSyncedProductValues =  {...newrelatedSyncedProductValues, store_platform: storeSelect.platform, shopify_product_data: productSelect};
      if (!productSelect) {
        toastFunction(toast, "success", "Para eliminar una vinculacion con Shopify es necesario seleccionar el producto", "", 3000);
        setGlobalLoading(false);
        return;
      }
    } 
  
    // Verificar si la tienda está vinculada con el producto relacionado
    const { vinculatedStore, vinculatedProduct } = await findStoreAndVinculatedProductInStores(productData?.related_synced_id, sellerStores);
    // console.log("vinculatedStore", vinculatedStore, shoppexStoreData);
    console.log("validacion", productData?.commercial_partner_price_with_VAT_with_service_fee)
    if (is_a_buying_club) {
      newrelatedSyncedProductValues.premium_service_fee = premiumServiceFeeValue - productData?.commercial_partner_price_with_VAT_with_service_fee;
    
    }
  
  
    const newFixedprices = isShoppexStore ? {
      ...shoppexStoreData.fixed_prices,
      [newrelatedSyncedProductValues.product_id]: newrelatedSyncedProductValues.with_fixed_price,
    } : {};
  
    try {
      if (!vinculatedStore) {
        // Si no está vinculada, agregar el nuevo producto sincronizado a la tienda del seller
        if (isShoppexStore) {
          await updateDocument("stores", shoppexStoreData.id, { fixed_prices: newFixedprices });
          newrelatedSyncedProductValues = { ...newrelatedSyncedProductValues, sections: [] };
        }
        console.log("validacion", shoppexStoreData, newrelatedSyncedProductValues, )


        resultVinculation = await createSubcollectionDocument(
          "stores",
          shoppexStoreData.id,
          "related_products",
          newrelatedSyncedProductValues.id,
          newrelatedSyncedProductValues
        );
      } else {
        if (isShoppexStore) {
          await updateDocument("stores", shoppexStoreData.id, { fixed_prices: newFixedprices });
          resultVinculation = await updateSubcollectionDocument(
            "stores",
            vinculatedStore.id,
            "related_products",
            vinculatedProduct.id,
            newrelatedSyncedProductValues
          );
        }
      }
  
      if (resultVinculation) {
        toastFunction(toast, "success", t("succefullVinculate"), "", 4000);
        onClose();
        onCloseDetailProductModal()
      }
    } catch (error) {
      console.error("Error during product vinculation:", error);
      toastFunction(toast, "error", "Error al vincular el producto.", "", 4000);
    } finally {
      setGlobalLoading(false);
    }
  };

  const handleToggleSwitchFixedPrice = (e) => {
    // Actualiza el estado utilizando el anterior valor de product_is_active
    setRelatedSyncedShoppexProductValues((prevValues) => ({
            ...prevValues,
            with_fixed_price: e.value,

        }));
  };

  const handleToggleSwitchIsActive = (e) => {
    // Actualiza el estado utilizando el anterior valor de product_is_active
    setRelatedSyncedShoppexProductValues((prevValues) => ({
            ...prevValues,
            is_active: e.value,

        }));
  };

  const onBlurSetFixedPrice = () => {
    if( relatedSyncedShoppexProductValues.fixed_price <= productData.commercial_partner_price_with_VAT){
        const sellerRevenue = relatedSyncedShoppexProductValues.suggested_retail_price - productData.commercial_partner_price_with_VAT_with_service_fee
        toastFunction(toast, "warn", "El precio minimo es el precio del proveedor", "", 4000);
        setRelatedSyncedShoppexProductValues((prevValues) => ({
            ...prevValues,
            fixed_price: productData.suggested_retail_price,
            basic_service_fee: sellerRevenue,
        }));
      }
  };

  const updateTagInVinculation = (fieldName, newValue) => {
    console.log("newValue", newValue, fieldName)
    if (fieldName === "tag_ids") {
      setRelatedSyncedShoppexProductValues((prevValues) => ({
        ...prevValues,
        [fieldName]: newValue?.map((value) => value),
      }));
    }
  };


  const messageYoutubeShortContent = (
    <div className="">
        <div className="ml-2">Recuerda que la relacion de aspecto de tu video debe ser 9:16 y que debe quedar subido a una cuenta de Youtube en la sección "shorts"
        </div>
    </div>
);

  const imageYoutubeShortURL = (
    <div >
        <img alt="logo" src="https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/Assets%2Fejemplo%20URL%20de%20Short%20de%20YouTube.jpeg?alt=media&token=f36b0171-1b13-4ff9-97d6-955265d0fe09" />
    </div>
  );

  useEffect(() => {
    const fetchVinculatedStore = async () => {
      setGlobalLoading(true);
      try {
        // SI el producto está marcado con un id de vinculación, buscamos la tienda
        if (productData?.related_synced_id) {
          // Encontramos entre las tiendas del seller el producto con el que coincidan los ids de la sincronización y la tienda
          const { vinculatedStore, vinculatedProduct } = await findStoreAndVinculatedProductInStores(productData.related_synced_id, sellerStores);
          
          // console.log("vinculatedStore", vinculatedStore, vinculatedProduct);
          
          if (vinculatedStore) {
            let vinculatedProductSelected
            toastFunction(toast, "info", t("Estamos obteniendo tus productos"), "", 4000);
            setStoreSelect(vinculatedStore);
            setSelectedTags(vinculatedProduct.tag_ids)
            setRelatedSyncedShoppexProductValues(vinculatedProduct);
            if (vinculatedStore?.platform.code === 1) {  // Si la plataforma es Supplaii  buscamos en la coleccion 'stores' el objeto de la vinculacion 

              // Traemos los productos que esten relacionado con el businessId del usuarios
              fetchShoppexSellerProducts(userData?.business_id)
              const shoppexStoreData = await getDocumentById("stores", vinculatedStore.id)

              // console.log("shoppexProductData", shoppexStoreData)
              // Construimos el objeto de la vinculacion para que coincida con algun objeto de la lista de productos que se obtienen en `fetchShoppexSellerProducts`
                vinculatedProductSelected = {
                  id: vinculatedProduct?.synced_product_id,
                  name: vinculatedProduct?.synced_product_name,
                  suggested_retail_price: vinculatedProduct?.suggested_retail_price,
                  commercial_partner_price_with_VAT:  productData?.commercial_partner_price_with_VAT
              };
              setShoppexStoreData(shoppexStoreData);
              setDisabledProductAndStoreSelect(true);
              setProductSelect(vinculatedProductSelected);
              if (shoppexStoreData?.is_a_buying_club) {
                setPremiumServiceFeeValue(vinculatedProduct.premium_service_fee + productData?.commercial_partner_price_with_VAT_with_service_fee)
              }
              setComparativePriceValue(vinculatedProduct?.comparative_price || null)
    
            } else if (vinculatedStore.platform.code === 2) {
              // console.log("vinculatedProduct", vinculatedProduct)
             vinculatedProductSelected = {
                id: vinculatedProduct?.synced_product_id,
                name: vinculatedProduct?.synced_product_name,
              };
              // setProductHasVinculated(true);
              setDisabledProductAndStoreSelect(true);
              fetchShopifyProductsData(vinculatedStore);
              setProductSelect(vinculatedProduct?.shopify_product_data);
            }
          }
        }
      } catch (error) {
        console.error("Error emparejando los datos del producto vinculado con la tienda Shoppex:", error);
      } finally {
        // Asegúrate de que setGlobalLoading se llame independientemente del éxito o fallo
        setGlobalLoading(false);
      }
      // Actualizar la bandera de primer render después del primer render
      isFirstRender.current = false;
    };
  
    fetchVinculatedStore();
  }, [userData]);
  


  return (
    <form onSubmit={vinculateProduct}>
      <div className="mt-8">
        <CustomSelect
          floatLabel={true}
          optionLabel={"name"}
          options={sellerStores}
          value={storeSelect}
          placeholder={t("input-placeholders.selectStore")}
          onChange={(e) => handleStoreSelect(e.target.value)}
          required={true}
          disabled={disabledProductAndStoreSelect}
        />
        
        {/* Que solo aparezca el select del producto cuando la tienda es shopify */}
        { storeSelect && storeSelect?.platform?.code  === 2 && (
          <CustomSelect
            floatLabel={true}
            optionLabel={"name"}
            options={productsData}
            value={productSelect}
            placeholder={t("input-placeholders.selectProduct")}
            onChange={(e) => handleProductSelect(e.target.value)}
            required={true}
            disabled={productsData?.length === 0 || disabledProductAndStoreSelect}
          />
        )}

        {/* Que solo aparezca los campos adicionales para la vinculacion cuando la tienda es Supplaii */}
        {(storeSelect?.platform?.code === 1 && !productHasVinculated ) &&(
           <InputsToSupplaiiStore 
            relatedSyncedShoppexProductValues={relatedSyncedShoppexProductValues}
            setRelatedSyncedShoppexProductValues={setRelatedSyncedShoppexProductValues} 
            t={t}
            globalLoading={globalLoading}
            shoppexStoreData={shoppexStoreData}
            selectedTags={selectedTags} setSelectedTags={setSelectedTags}
            updateTagInVinculation={updateTagInVinculation}
            comparativePriceValue={comparativePriceValue}
            setComparativePriceValue={setComparativePriceValue}
            storeSelect={storeSelect}
            premiumServiceFeeValue={premiumServiceFeeValue}
            setPremiumServiceFeeValue={setPremiumServiceFeeValue}
            productData={productData}
            onBlurSetFixedPrice={onBlurSetFixedPrice} 
            handleToggleSwitchFixedPrice={handleToggleSwitchFixedPrice}
            handleToggleSwitchIsActive={handleToggleSwitchIsActive}
            messageYoutubeShortContent={messageYoutubeShortContent}
            imageYoutubeShortURL={imageYoutubeShortURL}
          />
        )}
      </div>
      <div className="mt-12 grid gap-4">
        <MainButton
          type={"onSubmit"}
          label={t("vinculateProduct")}
          autoFocus
          loading={globalLoading}
          disabled={globalLoading  || productHasVinculated || (storeSelect?.platform?.code === 2 && disabledProductAndStoreSelect)
          }
        />
         { productData?.related_synced_id && (
            <DeleteButton
                type={"button"}
                label={t("Deshacer vinculacion")}
                loading={globalLoading}
                onClick={() => setVisibleConfirmDialog(true)}
                disabled={globalLoading}

            />)}
      </div>
        <CustomConfirmDialog
          visible={visibleConfirmDialog}
          onHide={() => setVisibleConfirmDialog(false)}
          header={t("confirmDialog-deleteVinculation.header")}
          message={t("confirmDialog-deleteVinculation.message")}
          icon="pi pi-question-circle"
          accept={handleDeleteVinculation}
          reject={() => setVisibleConfirmDialog(false)}
          acceptLabel={t("confirmDialog-deleteVinculation.acceptLabel")}
          rejectLabel={t("confirmDialog-deleteVinculation.rejectLabel")}
        />
    </form>
  );
};
VinculateProductForm.propTypes = {
  selectedSellerStore: PropTypes.object,
  userData: PropTypes.object,
  labelSuccess: PropTypes.string,
  labelDelete: PropTypes.string,
  onClose: PropTypes.func,
  productData: PropTypes.object,
  toast: PropTypes.object,
};

export default VinculateProductForm;





const InputsToSupplaiiStore = ({
  relatedSyncedShoppexProductValues,
  setRelatedSyncedShoppexProductValues,
  t,
  globalLoading,
  shoppexStoreData,
  selectedTags,
  setSelectedTags,
  updateTagInVinculation,
  comparativePriceValue,
  setComparativePriceValue,
  storeSelect,
  premiumServiceFeeValue,
  setPremiumServiceFeeValue,
  productData,
  onBlurSetFixedPrice,
  handleToggleSwitchFixedPrice,
  handleToggleSwitchIsActive,
  messageYoutubeShortContent,
  imageYoutubeShortURL
}
)  => {

  return <>
    <CustomInputText
      floatLabel={true}
      type={"text"}
      value={relatedSyncedShoppexProductValues?.custom_title}
      placeholder={t("input-placeholders.customTitle")}
      onChange={(e) => setRelatedSyncedShoppexProductValues((prevValues) => ({
        ...prevValues,
        custom_title: e?.target?.value,
        custom_title_for_search: formatForSearch(e?.target?.value)
      }))}
      disabled={globalLoading}
      required={true} />
    <CustomMultiSelect
      optionLabel={""}
      options={shoppexStoreData?.tag_ids || []}
      value={selectedTags}
      onValue={setSelectedTags}
      placeholder={t("input-placeholders.tagStores")}
      updateField={updateTagInVinculation}
      fieldNameToUpdate={"tag_ids"}
      disabled={globalLoading} />
    <CustomInputNumber
      required={false}
      floatLabel={true}
      value={comparativePriceValue}
      min={0}
      prefix={"$ "}
      onChange={(e) => {
        const comparativePrice = e.value;
        setComparativePriceValue(comparativePrice);
      } }
      // max={walletUser.balance_available}
      placeholder={t("input-placeholders.comparativePrice")}
      disabled={!storeSelect?.is_a_buying_club} />
      {storeSelect?.is_a_buying_club && (
        <CustomInputNumber
          required={false}
          floatLabel={true}
          value={premiumServiceFeeValue}
          min={0}
          prefix={"$ "}
          onChange={(e) => {
            const premiumValue = e.value;
            setPremiumServiceFeeValue(premiumValue);
          } }
          placeholder={t("input-placeholders.premiumServiceFee")}
          disabled={!storeSelect?.is_a_buying_club} />
      )}

    <CustomInputNumber
      required={true}
      floatLabel={true}
      value={relatedSyncedShoppexProductValues?.fixed_price}
      prefix={"$ "}
      placeholder={t("input-placeholders.fixedPrice")}
      onChange={(e) => {
        const sellerRevenue = e.value - (productData?.commercial_partner_price_with_VAT_with_service_fee);
        setRelatedSyncedShoppexProductValues((prevValues) => ({
          ...prevValues,
          fixed_price: e.value,
          basic_service_fee: sellerRevenue,
        }));
      } }
      onBlur={onBlurSetFixedPrice}
      disabled={globalLoading} />
      
    <CustomInputNumber
      required={true}
      floatLabel={true}
      value={relatedSyncedShoppexProductValues?.product_cost}
      min={0}
      prefix={"$ "}
      placeholder={t("input-placeholders.productCost")}
      disabled={true} />
    <CustomInputNumber
      required={true}
      floatLabel={true}
      value={relatedSyncedShoppexProductValues?.basic_service_fee}
      min={0}
      prefix={"$ "}
      // max={walletUser.balance_available}
      placeholder={t("input-placeholders.basicServiceFee")}
      disabled={true} />
  
    <Accordion  activeIndex={false}>
                <AccordionTab header="Vincular Short de Youtube">
                
                <Message
                    style={{
                        border: 'solid #696cff',
                        borderWidth: '0 0 0 6px',
                        fontWeight: "bold"
                    }}
                    className="border-primary w-full mt-2 mb-4 "
                    severity="info"
                    content={messageYoutubeShortContent}
                    />
                   <Message
                    style={{
                        border: 'solid #696cff',
                        borderWidth: '0 0 0 6px',
                    }}
                    className="border-primary w-full mb-4 bg-cover "
                    severity="info"
                    content={imageYoutubeShortURL}
                    />
                <div>
                  {/* Input para ingresar el ID del short de YouTube */}
                  <CustomInputText
                    floatLabel={true}
                    type={"text"}
                    value={relatedSyncedShoppexProductValues?.vertical_video_src}
                    placeholder={"Ingresa el ID del SHORT de YouTube "}
                    onChange={(e) => {
                      const youtubeShortId = e?.target?.value;

                      setRelatedSyncedShoppexProductValues((prevValues) => ({
                        ...prevValues,
                        vertical_video_src: youtubeShortId,
                      }));
                    } }
                  />

                  {/* Renderizado condicional del iframe solo si hay un ID */}
                  {relatedSyncedShoppexProductValues?.vertical_video_src && (
                   <div style={{ marginTop: "20px" }}>
                      <a href={`https://www.youtube.com/shorts/${relatedSyncedShoppexProductValues?.vertical_video_src}`} target="_blank" rel="noopener noreferrer">
                        Puedes verificar tu Short dando clik aquí
                      </a>
                    </div>
                  )}
                </div>
                </AccordionTab>
    </Accordion>

    <span className="flex items-center gap-4 mt-4 mb-4">
      <CustomInputSwitch
        checked={relatedSyncedShoppexProductValues?.with_fixed_price}
        name={"with_fixed_price"}
        onChange={(e) => handleToggleSwitchFixedPrice(e)}
        disabled={globalLoading} /> Fijar el precio
    </span>


    <p>{t("fixedPriceMessage")}</p>
    <span className="flex items-center gap-4 mt-4 mb-4">
      <CustomInputSwitch
        checked={relatedSyncedShoppexProductValues?.is_active}
        name={"is_active"}
        onChange={(e) => handleToggleSwitchIsActive(e)}
        disabled={globalLoading} /> Activar el producto
    </span>
  </>;
}

