import { BiChevronLeft } from "react-icons/bi";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { useTranslation } from "react-i18next";
import CustomTable from "../../Components/CustomTable/CustomTable";
import AuxButton from "../../Components/AuxButton/AuxButton";
import { useEffect, useState } from "react";
import MainButton from "../../Components/MainButton/MainButton";
import { Toast } from "primereact/toast";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import CustomModal from "../../Components/CustomModal/CustomModal";
import ShoppexStoreForm from "../../Components/ShoppexStoreForm/ShoppexStoreForm";
import DetailReviewForm from "../../Components/DetailReviewForm/DetailReviewForm.jsx";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext";
import useShopifyRequest from "../../hooks/useShopifyRequest";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase.js";
import StatusLight from "../../Components/StatusLight/StatusLight.jsx";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import CustomInputSwitch from "../../Components/FormComponents/CustomInputSwitch/CustomInputSwitch.jsx";
import { ReviewStars } from "../../Components/ReviewWidget/ReviewWidget.jsx";
// import styles from "./MyShoppexStores.module.css"
// Componente que se encarga de mostrar y seleccionar las tiendas de Shopify asociadas al seller
export default function MyShoppexStores() {
  const { userData, userSellerOwnerData} = useAuthContext();
  const userOwnerData = userSellerOwnerData || userData
  const { t } = useTranslation("Stores");
  const { toast } = useShopifyRequest();
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const { languages, currencyTypes } = useGlobalContext();

  const [storesData, setStoresData] = useState(userOwnerData?.seller_stores || []);
  const [visibleShoppexStoreForm, setVisibleShoppexStoreForm] = useState(false);
  const [visibleShoppexReviewForm, setVisibleShoppexReviewForm] = useState(false);
  const [selectedRowStoreData, setSelectedRowStoreData] = useState(null);
  const [selectedRowReviewData, setSelectedRowReviewData] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);

  const [reviewsData, setReviewsData] = useState([]);

  const CustomStoresTableColumns = [
    { 
      field: "id", header: t("table-headers.store"),
      sortable: false
    },
    { field: "name", header: t("table-headers.name"),
      filterable: false,
      sortable: false,  
    },
    { field: "default_currency", header: t("table-headers.currency"),
      filterable: false,
      body : (rowData) => {
        const findedCurrency = currencyTypes?.find((currency) => currency?.code === rowData?.default_currency)
        return findedCurrency?.name
      } 
    },
    { field: "default_language", header: t("table-headers.language") ,
      filterable: false,
      sortable: false,  
      body : (rowData) => {
        const findedLanguage = languages?.find((language) => language?.code === rowData?.default_language)
        return findedLanguage?.name
      } 
    },
    {
      field: "vercel_project_id",
      header: t("table-headers.storeState"),
      filterable: false,
      sortable: false, 
        // Columna para mostrar el estado usando el componente StatusLight
      body: (rowData) => (
        <p>
        {(rowData?.vercel_project_id && rowData?.vercel_project_id !== "") ? "Abierta": "Cerrada"}
      </p>
      ),
    },
    {
      field: "is_active",
      header: t("table-headers.webAppStatus"),
      filterable: false,
      sortable: false, 
        // Columna para mostrar el estado usando el componente StatusLight
        body: (rowData) => (
          <StatusLight
            color={rowData?.is_active  ? "green": "red"}
            
          />
  
        ),
    },
    // Columna para editar la tienda
    {
      header: "Editar",
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <AuxButton
          data={rowData}
          disable={false}
          label={t("edit")}
          style={{
            backgroundColor: "var(--main-color)",
            color: "white",
            fontSize: "var(--font-size-h5)",
          }}
          onClick={openStoreModal}
        />
      ),
    },
  ];

  const CustomReviewsTableColumns = [
    {
      header: t("table-headers.createdAt"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div className="card flex justify-content-center">
          <span>
            {rowData?.created_at?.toDate()?.toLocaleString()}
          </span>
        </div>
      ),
    },
    { 
      field: "store_name", header: t("table-headers.store"),
      sortable: false,
      filterable: false,

    },
     { 
      field: "product_name", header: t("table-headers.product"),
      sortable: false,
      filterable: false,
    },
     { 
      field: "related_customer_name", header: t("table-headers.customerName"),
      sortable: false,
      filterable: false,

    },
    { 
      field: "review_text", header: t("table-headers.comment"),
      sortable: false,
      filterable: false,
    }, 
    { 
      field: "score", header: t("table-headers.score"),
      sortable: false,
      filterable: false,
      body : (rowData) => {
        return (
          <div>
              <ReviewStars totalScore={rowData?.score || 0} readOnly={true}/>
          </div>
        )
      }
    }, 
    { field: "is_published", header: t("table-headers.post"),
      filterable: false,
      sortable: false,
      
      body : (rowData) => {
        return (
           <div className="flex justify-center">
              <CustomInputSwitch
                checked={rowData?.is_published}
                // onChange={(e) => handleInputChange("is_published", e.target.value)}
                disabled={true}
              />
          </div>
        )
      } 
    },
    // Columna para editar la tienda
    {
      header: "Editar",
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <AuxButton
          data={rowData}
          disable={false}
          label={t("edit")}
          style={{
            backgroundColor: "var(--main-color)",
            color: "white",
            fontSize: "var(--font-size-h5)",
          }}
          onClick={openReviewModal}
        />
      ),
    },
  ];

  // Función para abrir el modal de tiendas
  const openStoreModal = (store) => {
    setVisibleShoppexStoreForm(true);
    if (store.id) { setSelectedRowStoreData(store);
    } else { setSelectedRowStoreData(null)}
  };
    // Función para abrir el modal de tiendas
  const openReviewModal = (review) => {
    setVisibleShoppexReviewForm(true);
    if (review.id) { setSelectedRowReviewData(review);
    } else { setSelectedRowReviewData({})}
  };

  useEffect( function loadInitial() {
  let unsubscribeReviews;
   if( userOwnerData && userOwnerData?.seller_stores ) {
    const storeIds = userOwnerData?.seller_stores?.map((store) => store.id);
    const qShoppexReviews = query(collection(db, "reviews"),
        where("store_id", "in", storeIds )
      );
       unsubscribeReviews = onSnapshot(qShoppexReviews, (resultQuery) => {
        const reviewsDocs = resultQuery.docs?.map(doc => doc.data());
        // Utilizar flatMap para combinar todos los objetos en un solo array
        const allReviews = reviewsDocs.flatMap(reviewsDoc => {
          // Mapear cada review y agregar store_name a cada objeto
  
          return reviewsDoc?.reviews?.map(review => {
            return {
              ...review,
              store_id: reviewsDoc.store_id,
              store_name: reviewsDoc.store_name
            };
          });
        });
        
        // console.log("Todas las revisiones", allReviews);
        setReviewsData(allReviews);
        setGlobalLoading(false);
      });
   }
    // console.log( "carga inicial de datos", userData, userSellerOwnerData );
    const qShoppexStores = query(collection(db, "stores"),
      where("related_business_id", "==", userData.business_id)
    );
    const qShoppexCategories = query(collection(db, "category_products"));
  

    const unsubscribeShoppexStore = onSnapshot(qShoppexStores, (resultQuery) => {
      const shoppexStoresDocuments = resultQuery?.docs?.map(doc => doc.data());
      setGlobalLoading(false);
      setStoresData(shoppexStoresDocuments);
    });

    const unsubscribeCategories = onSnapshot(qShoppexCategories, (resultQuery) => {
      const categoriesDocuments = resultQuery.docs?.map(doc => doc.data());
      const categoriesDocumentsWithouAllCategories = categoriesDocuments?.filter((category) => category.id !== "ALLCATEGORIES")
      setCategoriesData(categoriesDocumentsWithouAllCategories);
      setGlobalLoading(false);
    });

    return () => {
      unsubscribeShoppexStore && unsubscribeShoppexStore() &&
      unsubscribeCategories && unsubscribeCategories() &&
      unsubscribeReviews && unsubscribeReviews()
    };

  }, [userData, userSellerOwnerData]);


  return (
    <div className="">
      <div style={{ marginBottom: "40px" }}>
        <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtnMyShoppexStores")} />
      </div>
        <CustomTable
          className="custom-table"
          columns={CustomStoresTableColumns} data={storesData} 
          action={() => {}}
      />
      <div className="mt-8">
        <MainButton
            loading={globalLoading}
            label={t("createShoppexStore")}
            onClick={openStoreModal}
        />
      </div>
      <h3 className="mt-12 mb-4 font-bold ">Comentarios de tus clientes</h3>
      <CustomTable
          className="custom-table"
          columns={CustomReviewsTableColumns} data={reviewsData} 
          action={() => {}}
      />
      <div className="mt-8 mb-12">
          <MainButton
          loading={globalLoading}
          label={t("createReview")}
          onClick={openReviewModal}
          />
      </div>
        <CustomModal
          visible={visibleShoppexStoreForm}
          setVisible={setVisibleShoppexStoreForm}
          object={selectedRowStoreData}
          editMode={t("updateStore")}
          createMode={t("createStore")}
          content={
            <ShoppexStoreForm
              onClose={() =>setVisibleShoppexStoreForm(false)}
              selectedSellerStore={selectedRowStoreData}
              userData={userSellerOwnerData || userData}
              categoriesData={categoriesData}
              labelDelete={t("deleteStore")}
              labelSuccess={t("createStore")}
              toast={toast}
            />
          }
          onClose={() => setVisibleShoppexStoreForm(false)}
        />
        <CustomModal
          visible={visibleShoppexReviewForm}
          setVisible={setVisibleShoppexReviewForm}
          object={selectedRowReviewData}
          editMode={t("updateReview")}
          createMode={t("createReview")}
          content={
            <DetailReviewForm
              selectedRowReviewData={selectedRowReviewData}
              labelDelete={t("deleteReview")}
              labelSuccess={t("createReview")}
              onClose={() => setVisibleShoppexReviewForm(false)}
              toast={toast}
            />
          }
          onClose={() => setVisibleShoppexReviewForm(false)}
        />
       
      <Toast ref={toast} />
    </div>
  );
}
