import PropTypes from "prop-types"
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import OptionsBoxes from "../OptionsBoxes/OptionsBoxes.jsx";
import QuantityControl from "../QuantityControl/QuantityControl.jsx";
import { generateRandomId } from "../../utils/generateRandomId.js";
import CustomInputNumber from "../FormComponents/CustomInputNumber/CustomInputNumber.jsx";
import { useTranslation } from "react-i18next";
import ProductSlider from "../ProductSlider/ProductSlider.jsx";
import MainButton from "../MainButton/MainButton.jsx";
import toastFunction from "../../utils/toastFunction.js";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog.jsx";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import CustomModal from "../CustomModal/CustomModal.jsx";
import { Toast } from "primereact/toast";
import SecondaryButton from "../SecondaryButton/SecondaryButton.jsx";
import CheckoutShippingCartForm from "../CheckoutShippingCartForm/CheckoutShippingCartForm.jsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GiConsoleController } from "react-icons/gi";

const ProductCartForm = ({selectedProduct = {}, selectedCartItem, onClose, toast, isCatalog, visibleProductCartFormPattern }) => {
    const navigate = useNavigate();
    console.log("selectedProduct y selectedCartItem", selectedProduct, selectedCartItem)
    console.log("validacion", selectedCartItem?.unit_price || selectedCartItem?.custom_cost || selectedProduct?.custom_cost || selectedProduct?.suggested_retail_price)
    const {globalLoading} = useLoaderContext();
    const hasEdit = !!Object.keys(selectedCartItem).length;
    const { t } = useTranslation("Products");
    const {addProductToShoppingCart, deleteProductToShoppingCart,  shoppingCart, catalogData } = useGlobalContext();
    
    const [searchParams] = useSearchParams();
    const catalogId = searchParams.get('catalogId');
    
    const [quantityProduct, setQuantityProduct] = useState(selectedCartItem?.quantity || selectedProduct?.quantity || 1);
    const [variation, setVariation] = useState(!selectedProduct?.options && selectedProduct?.variations ? selectedProduct?.variations[0] : null);
    const productOptions = selectedProduct?.options || [];

    const [visibleCheckoutForm, setVisibleCheckoutForm] = useState(false);
    console.log("selectedProduct", selectedProduct)
    const itemInitialValue = {
        id: selectedCartItem?.id || generateRandomId(),
        shopify_item_id: null,
        name: selectedCartItem?.name || selectedProduct?.product_name,
        sku: selectedCartItem?.sku || variation?.sku || null,
        sku_title: variation?.variation_label || "",
        variation_id: variation?.variation_id || null,
        quantity: selectedCartItem?.quantity || quantityProduct,
        product_success_match: true,
        synced_product_names: true,
        unit_discount: selectedCartItem?.unit_discount || 0,
        options: [],
        custom_cost: selectedCartItem?.custom_cost || selectedProduct?.custom_cost || 0,
        custom_profit: selectedProduct?.custom_profit || null,

        unit_price: selectedCartItem?.unit_price || selectedCartItem?.custom_cost || selectedProduct?.custom_cost || selectedProduct?.suggested_retail_price || 0,
        unitary_item_price: selectedCartItem?.unitary_item_price || selectedProduct?.unitary_item_price || 0,
        total_item_price: selectedCartItem?.total_item_price || 0,
        unitary_commercial_partner_charge_without_VAT: selectedCartItem?.unitary_commercial_partner_charge_without_VAT || selectedProduct?.commercial_partner_price_without_VAT,
        unitary_commercial_partner_charge_with_VAT_with_service_fee: selectedCartItem?.unitary_commercial_partner_charge_with_VAT_with_service_fee || selectedProduct?.commercial_partner_price_with_VAT_with_service_fee,
        unitary_commercial_partner_VAT:  selectedCartItem?.unitary_commercial_partner_VAT || selectedProduct?.commercial_partner_VAT_value,
        unitary_intermediary_charge: selectedCartItem?.unitary_intermediary_charge || selectedProduct?.service_fee || 0,
        product_id: selectedCartItem?.product_id || selectedProduct?.id, // id del producto de shoppex,
        product_title: selectedCartItem?.product_title || selectedProduct?.product_name || null,
        synced_product_id: selectedCartItem?.synced_product_id || selectedProduct?.id,
        related_commercial_partner_label: selectedCartItem?.related_commercial_partner_label || selectedProduct?.commercial_partner_label,
        related_commercial_partner_id: selectedCartItem?.related_commercial_partner_id|| selectedProduct?.commercial_partner_id,
        related_seller_id: selectedCartItem?.related_seller_id || selectedProduct?.related_seller_id,
        basic_service_fee: selectedCartItem?.basic_service_fee || selectedProduct?.suggested_retail_price - (selectedProduct?.commercial_partner_price_with_VAT_with_service_fee),
        service_fee: selectedCartItem?.service_fee || selectedProduct?.service_fee,
        // Direccion de despacho del producto 
        related_shipping_address_id: selectedCartItem?.related_shipping_address_id || selectedProduct?.related_shipping_address_id,
        is_upsell: null,
        is_downsell: null,
        is_crossell: null,
        item_is_confirmed: null,
        is_subscription: null,
    };

    const [ itemProductValues, setItemtProductsValues] = useState(itemInitialValue);
    const [selectedOptions, setSelectedOptions] = useState([]);
    console.log("itemProductValues", itemProductValues, itemInitialValue)
    const [visibleConfirmRemoveProductOfCart, setVisibleConfirmRemoveProductOfCart] = useState(false);
    const [ visibleProductCartForm, setVisibleProductCartForm ] = useState(false);
    const [ isBuying, setIsBuying ] = useState(false);

    
    const handleSelectOptions = (selectedOption, index) => {
        if (index < selectedProduct?.options.length) {
            // Verifica si ya se ha seleccionado una opción con el mismo display_name
            const existingOptionIndex = selectedOptions.findIndex(option => option?.option_id === selectedOption?.option_id);

            if (existingOptionIndex === -1) {
                // Agrega la opción seleccionada a la lista de opciones seleccionadas
                const newOptions = [...selectedOptions];
                newOptions[index] = selectedOption;
                // Actualiza la variación seleccionada basada en las opciones seleccionadas
                setSelectedOptions(newOptions);
                
                const matchingVariation = selectedProduct?.variations?.find(variation =>
                    variation.options.every(option =>
                        newOptions.some((selectedOption) => selectedOption.option_id === option)
                    )
                );
                if (matchingVariation) {
                    setVariation(matchingVariation);
                }
            } else {
                // Si la opción ya ha sido seleccionada, reemplaza la opción en el índice correspondiente
                setSelectedOptions(prevOptions => {
                    const newOptions = [...prevOptions];
                    newOptions[index] = selectedOption;
                    return newOptions;
                });
            }
        }
    };

    const handleSubmitCatalogProduct = async (e, isBuying) => {
        e.preventDefault();
        console.log("handleSubmitCatalogProduct", itemProductValues, itemInitialValue)

        setItemtProductsValues(itemInitialValue)
        // console.log("bandera 1 handleSubmitCatalogProduct",isBuying)
        if(!variation && selectedProduct?.options?.length > 0) {
            toastFunction(toast, "warn", "Debes elegir las opciones para agregar tu producto", "", 3000);
            if (!visibleProductCartFormPattern) {
                setVisibleProductCartForm(true);
                return
            }
            return
        }
        if(isBuying) {
            setIsBuying(isBuying)
        } else {
            setIsBuying(false)
        }
        setItemValuesAndAddToCart(itemInitialValue, isBuying);
        
    };
    
    const handleSubmitProductItem = async (e) => {
        e.preventDefault();
        setItemValuesAndAddToCart(itemProductValues);
    };
    console.log("variation", variation)


    const generateOptions = (options, optionsIds) => {
        return options
            .map(option => {
                // Buscar si hay coincidencia en los optionIds dentro de display_names
                const matchingDisplayName = option.display_names.find(displayName =>
                    optionsIds.includes(displayName.option_id)
                );
    
                if (matchingDisplayName) {
                    return {
                        is_size: option.is_size, // Copiar la clave is_size
                        option_label: matchingDisplayName.name // Usar el name del display_name coincidente
                    };
                }
                return null; // Ignorar si no hay coincidencia
            })
            .filter(option => option !== null); // Remover valores nulos
    };
    
    const setItemValuesAndAddToCart = (itemObject, isBuying) => {
        let skuImg = null;
        const productOptions = selectedProduct.options || selectedCartItem.options;
        const variationOptionsIds = variation.options || [];

        const optionsToConsolidateItem = generateOptions(productOptions, variationOptionsIds);


        // console.log("validacion opciones para consolidar el item",optionsToConsolidateItem )
        // Obtengo la imagen procipal de la variacion
        if (variation) {
            skuImg = variation.images.find((img) => img.is_main_image === true);
        }else {
            skuImg = selectedCartItem?.sku_img  || selectedProduct?.variations[0]?.images?.find((img) => img.is_main_image === true);
        }
        itemObject.quantity = quantityProduct;
        itemObject.total_item_price = itemObject.unit_price * quantityProduct;
        itemObject.sku = variation?.sku  || selectedCartItem?.sku || selectedProduct?.variations[0]?.sku;
        itemObject.sku_title = variation?.variation_label  ||  selectedCartItem?.sku_title ||selectedProduct?.variations[0]?.variation_label;
        itemObject.sku_img = skuImg?.img_url || selectedProduct?.main_img?.url || null;
        itemObject.variation_id = variation?.variation_id;
        // itemObject.options = selectedProduct?.options || selectedCartItem?.options || [];
        itemObject.options = optionsToConsolidateItem;

        if (isCatalog) {
            // Definir el precio unitario, dándole prioridad a custom_cost, seguido de unit_price o suggested_retail_price.
            itemObject.unit_price = itemObject?.unit_price || itemObject?.custom_cost || selectedCartItem?.unit_price || selectedProduct?.suggested_retail_price;
        
            // Calcular el precio total del artículo
            itemObject.total_item_price = itemObject.unit_price * quantityProduct;
        }
        

        // console.log("antes de agregar al carrito", itemObject)
      
            if (isBuying ) {
                addProductToShoppingCart(hasEdit, itemObject);
                setVisibleCheckoutForm(true);
                return
            } else if(!isCatalog && validateFields(itemObject)){
                addProductToShoppingCart(hasEdit, itemObject);
                onClose();
                return
            }else {
                addProductToShoppingCart(hasEdit, itemObject);
                navigate(`/catalog/?catalogId=${catalogId}`)

            }

            onClose();
            toastFunction(toast, "success", "Producto agregado al carrito correctamente", "", 3000);
        
    };

    const validateFields = (itemProductValues) =>  {
        if ( itemProductValues.unit_price < selectedProduct?.commercial_partner_price_with_VAT) {
            toastFunction(toast, "warn", "Tu precio no puede ser menor a el costo del producto", "", 3000);
            return false
        }
        if (!variation){
            toastFunction(toast, "warn", "Debes elegir la variacion en base a las opciones", "", 3000);
            return false
        }
      return true
    };

    const deleteItemOfCounter = (itemProductValues) => {
        // console.log("entre", hasEdit)
        if (hasEdit && shoppingCart.some((shoppingItem) => shoppingItem?.id === itemProductValues?.id)) {
            setVisibleConfirmRemoveProductOfCart(true);
        }else {
            onClose();
        }
        if(visibleConfirmRemoveProductOfCart) {
            deleteProductToShoppingCart(itemProductValues)
            onClose();
        }
    };

    useEffect(function asignVariationAndOptions(){
        if (hasEdit) {
            // Encontramos la variacion que contiene el sku del item que se esta editando
            const matchingVariation = selectedProduct?.variations?.find((productVariation) => productVariation.sku === selectedCartItem?.sku)
            setVariation(matchingVariation)
            const optionsArray = selectedProduct?.options?.map(objeto => {
                    // Buscamos por cada opcione del producto al que corresponde el item el nombre que conforma la variacion
                    const matchingDisplayName = objeto.display_names.find(display => {
                        return matchingVariation?.options?.includes(display.option_id);
                    });
                    // Si se encontró un displayName que coincide, devolverlo
                    if (matchingDisplayName) {
                        return matchingDisplayName;
                    }
                });

            setSelectedOptions(optionsArray);
            // console.log("Verificacion", optionsArray, selectedProduct?.options, matchingVariation?.options)
        }             
    }, []);

    useEffect(() => {
        console.log("entre al efecto")
        itemInitialValue.quantity = quantityProduct;
        itemProductValues.quantity = quantityProduct;
        selectedProduct.quantity = quantityProduct;
        setItemtProductsValues(itemInitialValue)
    },[quantityProduct]);

    return (
        <>
            <form onSubmit={isCatalog ? handleSubmitCatalogProduct:  handleSubmitProductItem}>
                {!visibleProductCartFormPattern && (<ProductSlider
                    product_data={selectedProduct}
                />)}
                {isCatalog  && (<h2
                    className="main-gradient-text "
                    style={{ color: "var(--font-head-color)", marginBottom: "20px" }}
                    >
                        {!visibleProductCartFormPattern ? selectedProduct?.product_name : "Selecciona las opciones"}
                    </h2>
                )}

                {selectedProduct?.description &&(<p>
                    {selectedProduct?.description}
                </p>)}
                <span 
                    className="flex mt-8"
                >
                    <QuantityControl
                        qty={quantityProduct}
                        deleteItem={() => deleteItemOfCounter(selectedCartItem)}
                        updateQty={setQuantityProduct}
                    />
                </span>
                {productOptions.map((option, index) => {
                    // Encuentra la opción seleccionada correspondiente al índice actual
                    const selectedOption = selectedOptions[index];
                    return (
                        <OptionsBoxes
                            key={index}
                            optionName={option.type}
                            optionValues={option?.display_names}
                            optionValueSelected={selectedOption}
                            onSelectOptionValue={(option) => handleSelectOptions(option, index)}
                        />
                    );
                })}
                {variation && (
                    <h4 className="mt-4 flex justjaify-center"> {variation?.variation_label}</h4>
                )}
                { !isCatalog &&(<div className="mt-8">
                    <h5 className="">¿Cuanto ganaras?</h5>
                    <CustomInputNumber
                        required={true}
                        floatLabel={true}
                        value={itemProductValues?.unit_price}
                        prefix={"$ "}
                        placeholder={t("input-placeholders.fixedPrice")}
                        onChange={(e) => {
                            const sellerRevenue = e.value -( selectedProduct?.commercial_partner_price_with_VAT_with_service_fee );
                            setItemtProductsValues((prevValues) => ({
                            ...prevValues,
                            unit_price: e.value,
                            basic_service_fee: sellerRevenue,
                            }));
                        }}
                    />
                    <CustomInputNumber
                        required={true}
                        floatLabel={true}
                        value={selectedProduct?.commercial_partner_price_with_VAT_with_service_fee }
                        min={0}
                        prefix={"$ "}
                        placeholder={t("input-placeholders.productCost")}
                        disabled={true}
                    />
                    <CustomInputNumber
                        required={true}
                        floatLabel={true}
                        value={itemProductValues?.basic_service_fee}
                        min={0}
                        prefix={"$ "}
                        // max={walletUser.balance_available}
                        placeholder={t("input-placeholders.basicServiceFee")}
                        disabled={true}
                    />

                </div>)}
                <div className="mt-12 grid gap-4">
                    {
                        isCatalog  && (
                        <MainButton 
                            disabled={isCatalog ? false: !variation }
                            type="button"
                            onClick={(e) => handleSubmitCatalogProduct(e, true)}
                            loading={globalLoading}
                            label={"COMPRAR AHORA - PAGAR AL RECIBIR"}
                        />
                        )
                    }
                    {
                       (
                        (isCatalog && catalogData?.full_contact_number) || !isCatalog)  && (
                        <SecondaryButton 
                            disabled={isCatalog ? false: !variation }
                            type="submit"
                            // onClick={(e) => handleSubmitCatalogProduct(e)}
                            loading={globalLoading}
                            label={"AGREGAR AL CARRITO"}
                        />
                       )
                       
                    }
                    
                    
                </div>
            </form>
             <CustomConfirmDialog
                visible={visibleConfirmRemoveProductOfCart}
                onHide={() => setVisibleConfirmRemoveProductOfCart(false)}
                header={t("confirmDialog-deleteProductFromCart.header")}
                message={t("confirmDialog-deleteProductFromCart.message")}
                icon="pi pi-question-circle"
                accept={() => deleteItemOfCounter(selectedCartItem)}
                reject={() => setVisibleConfirmRemoveProductOfCart(false)}
                acceptLabel={t("confirmDialog-deleteProductFromCart.acceptLabel")}
                rejectLabel={t("confirmDialog-deleteProductFromCart.rejectLabel")}
            />
            <CustomModal
                visible={visibleProductCartForm}
                setVisible={setVisibleProductCartForm}
                object={selectedProduct}
                content={
                    <ProductCartForm
                        selectedCartItem={{}}
                        selectedProduct={selectedProduct }
                        onClose={() => setVisibleProductCartForm(false)}
                        visibleProductCartFormPattern={visibleProductCartForm}
                        toast={toast}
                        isCatalog={isCatalog}
                        buyNow={isBuying}
                    />
                }
                onClose={() => setVisibleProductCartForm(false)}
            />
            <Toast ref={toast} />
            <CustomModal
                visible={visibleCheckoutForm}
                setVisible={setVisibleCheckoutForm}
                object={selectedProduct}
                content={
                    <CheckoutShippingCartForm
                        toast={toast}
                        isCatalog={isCatalog}
                        catalogData={catalogData}
                        onClose={() => setVisibleCheckoutForm(false)}
                    />
                }
                onClose={() => setVisibleCheckoutForm(false)}
            />
        </>
    )
}
ProductCartForm.propTypes = {
    selectedProduct: PropTypes.object,
    selectedCartItem: PropTypes.object,
    onClose: PropTypes.func,
    toast: PropTypes.object,
    isCatalog: PropTypes.bool,
    visibleProductCartFormPattern: PropTypes.bool
}
export default ProductCartForm;