/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import SocialCausesCard from './SocialCausesCard'
import HorizontalWrapper from './HorizontalWrapper';

const  SocialCauses = ({  selectedCause, setSelectedCause, hasEditCause, setHasEditCause, visibleCauseModal, setVisibleCauseModal, shoppexStoreValues, setShoppexStoreValues,  causes, toast, t}) => {
  const [ytApiLoaded, setYtApiLoaded] = useState(false);

  useEffect(() => {
    if (!window.YT) {
      console.log("Cargando API");
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = () => {
        setYtApiLoaded(true); // Marcar como cargada cuando la API esté lista
        console.log("API Cargada");
      };
    }
    // Cuando la API está lista, se ejecuta esta función
    window.onYouTubeIframeAPIReady = () => {
      console.log("YouTube API lista");
      setYtApiLoaded(true);
    };
  }, []);

  useEffect(() => {}, [causes])
  return (
    <div className="my-7">
      <HorizontalWrapper>
        {causes?.map((cause, index) => {
          if (!cause?.isActive) return null
          return (
              <SocialCausesCard 
                visibleCauseModal={visibleCauseModal}
                setVisibleCauseModal={setVisibleCauseModal}
                shoppexStoreValues={shoppexStoreValues}
                setShoppexStoreValues={setShoppexStoreValues}
                key={cause.id}
                index={index}
                selectedCause={selectedCause}
                setSelectedCause={setSelectedCause}
                cause={cause}
                hasEditCause={hasEditCause}
                setHasEditCause={setHasEditCause}
                t={t}
                toast={toast}
                />
          )
        })}
      </HorizontalWrapper>
    </div>
  )
}

export default SocialCauses;
