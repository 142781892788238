function formatDateES(dateInput) {
    let dateObj;

    // Intentar convertir el input a un objeto Date si no lo es
    if (dateInput instanceof Date && !isNaN(dateInput.getTime())) {
        dateObj = dateInput;
    } else if (typeof dateInput === "string" || typeof dateInput === "number") {
        dateObj = new Date(dateInput);
    } else {
        throw new Error("Invalid date format or type");
    }

    // Validar si la fecha es válida
    if (isNaN(dateObj.getTime())) {
        throw new Error("Invalid date object");
    }

    // Extraer componentes de la fecha
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1; // Los meses son base 0
    const day = dateObj.getDate();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();

    // Formatear los componentes
    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    // Determinar AM/PM
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");

    // Construir la fecha formateada
    return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes} ${period}`;
}
  export default formatDateES;
  